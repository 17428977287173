import {
  notification,
  Button,
  Table,
  Space,
} from "antd";
import { useContext, useEffect, useState, useMemo } from "react";
import { ConfiguratorContext, S3UrlMapContext, UserListContext, UsersContext } from "../context";
import { QuoteComment, CommentTopic } from "../api/models"
import _ from "lodash";
import { useIntl } from "react-intl";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import {  PlusOutlined, } from "@ant-design/icons";
import dayjs from "dayjs";
import {QuoteCommentRequestOptions} from "../api";
import QuoteCommentCard, {EditQuoteCommentCard} from "./QuoteCommentCard";
import Utils from "../util/util";
import BmMentions from "./BmMentions";

const NOT_FOUND = -1;

type S3Map = Record<string, string>
export interface CommentActivityListProps {
  topic: CommentTopic
  quoteId:string | undefined
  tags?:string[] | undefined
  topicTags?: string[] | undefined
  hideSearch?:boolean
};
const CommentActivityList = (props: CommentActivityListProps) => {

  const { quoteId } = props;

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [editCommentId, setEditCommentId] = useState<number | undefined>();
  const [showAddComment, setShowAddComment] = useState<boolean>(false);
  const [search, setSearch] = useState<string | undefined>();
  const commentOptions = {
    showHidden: configurator.isAdmin(),
    topic: [ props.topic ],
  };
  const [_s3UrlMap, s3UrlMapAsync] = useAsyncState<S3Map>();
  const [quoteCommentLst, quoteCommentLstAsync] = useAsyncState<QuoteComment[]>();

  const { userLstAsync } = useContext<UserListContext>(UsersContext);

  useEffect(() => {
    setSearch(props.tags?.join(" "));
  }, [props.tags] );

  useEffect(() => {
    setShowAddComment(false);
  }, [props.tags, props.quoteId, props.topic] );

  const s3UrlContext = useMemo(() => ({
    s3UrlMapAsync 
  }), [s3UrlMapAsync ]);

  const handleShowAddComment = () => {
    setEditCommentId(undefined);
    setShowAddComment(true);
  }

  const handleEditComment = (commentId:number) => {
    setEditCommentId(commentId);
    setShowAddComment(false);
  }

  useEffect(() => {
    if ( quoteId?.length ) {
      loadS3Url(s3UrlMapAsync, commentOptions);
      loadQuoteComments(quoteCommentLstAsync, commentOptions)
        //update last viewed for all comments
        .then( comments => Promise.all( comments?.map( c => updateQuoteCommentLastViewed(quoteId, c.id) ) || [] ) );
    }
  }, [props.quoteId, commentOptions.showHidden, props.topic] );

  const loadS3Url = async (s3UrlMapAsync:AsyncState<S3Map>, options?:QuoteCommentRequestOptions): Promise<S3Map | undefined> => {
    if ( !quoteId ) return;

    s3UrlMapAsync.isLoading();
    try {
      const resp = await configurator.api.fetchQuoteCommentDocumentUrls( quoteId, options );
      s3UrlMapAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch document urls. " + errorMsg });
      s3UrlMapAsync.setFail(e.message);
    }
    return;
  }

  const updateQuoteCommentLastViewed = async (quoteId:string, commentId:number) : Promise<QuoteComment | undefined> => {
    if ( !quoteId ) return;

    try {
      const resp = await configurator.api.updateQuoteCommentLastViewed(quoteId, commentId);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update quote comment last viewed. " + errorMsg });
    }
    return;
  }

  const loadQuoteComments = async (quoteCommentLstAsync:AsyncState<QuoteComment[]>, options?:QuoteCommentRequestOptions) : Promise<QuoteComment[] | undefined> => {
    if ( !quoteId ) return;

    quoteCommentLstAsync.isLoading();
    try {
      const resp = await configurator.api.fetchQuoteComments(quoteId, options);
      quoteCommentLstAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch document urls. " + errorMsg });
      quoteCommentLstAsync.setFail(e.message);
    }
    return;
  }

  const handleAddedComment = (comment:QuoteComment) => {
    const lst = [ comment ].concat( quoteCommentLst || [] );
    quoteCommentLstAsync.setDone( lst );
    setShowAddComment(false);
  }

  const handleEditedComment = (comment:QuoteComment) => {
    const ndx = quoteCommentLst?.findIndex( c => c.id === comment.id ) ?? NOT_FOUND;
    if ( ndx === NOT_FOUND ) return;

    const lst = [...(quoteCommentLst || [])];

    if ( comment.hidden ) {
      lst.splice( ndx, 1 );
    }
    else {
      lst.splice( ndx, 1, comment );
    }
    quoteCommentLstAsync.setDone( lst );
    setEditCommentId(undefined);
  }

  const datasource = quoteCommentLst ? [...quoteCommentLst]
    .filter( c => {
      if ( !search ) return true;
      const searchFilter = Utils.splitByWhitespacePreservingQuotes(search);
      const searchTxt = [c.text].concat(c.tags?.flat()).join(" ");
      return Utils.searchValue( searchFilter, searchTxt );
    })
    .sort((a,b) => dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1)
    : [];
  const pageSize = 7;
  const pagination = ((datasource?.length || 0) > pageSize) ? { pageSize } : false; 



  return <>
    <S3UrlMapContext.Provider value={s3UrlContext}>
      <Space direction="vertical" style={{width: "100%"}} size="small">
        {(!props.hideSearch && !showAddComment) &&
          <BmMentions 
            allowClear
            rows={1}
            value={search} 
            placeholder="Search Comments" 
            onChange={(txt) => setSearch( txt)} 
            mentions={{
              '@': userLstAsync?.val?.map( u => ({value: _.upperFirst(_.camelCase( u.name)), label: u.name })),
              '#': props.tags?.map( t => ({value: _.upperFirst(_.camelCase( t )), label: t }))
              }}
          /> }

        <div style={{display:"flex", flexDirection: "row-reverse", }} >
          {!showAddComment &&
            <Button icon={<PlusOutlined />} shape="circle" type="primary" size="small"
              onClick={handleShowAddComment}
            />}
        </div> 

        {showAddComment &&
          <EditQuoteCommentCard 
            quoteId={quoteId}
            comment={{
              topic: props.topic,
              internal: true,
              tags: props.tags,
            }}
            reset={showAddComment}
            topicTags={props.topicTags}
            onCancel={() => setShowAddComment(false)}
            onSave={handleAddedComment}
          />}

        <Table
          className="mobile-table"
          size="small"
          bordered={false}
          rowKey="id"
          loading={quoteCommentLstAsync.isLoading()}
          dataSource={datasource}
          pagination={pagination}
          columns={[{ render: (comment) => 
            (editCommentId === comment.id ) 
              ? <EditQuoteCommentCard comment={comment} 
                quoteId={quoteId}
                topicTags={props.topicTags}
                onCancel={() => setEditCommentId(undefined)}
                onSave={handleEditedComment}
              /> 
              : <QuoteCommentCard comment={comment} 
                onEdit={handleEditComment} 
              />
          }]}
        />
      </Space>

    </S3UrlMapContext.Provider>
  </>
}



export default CommentActivityList;
