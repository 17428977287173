import { Card, Col, DatePicker, Input, InputNumber, Row, Select } from "antd";
import { AssemblyAttribute, AssemblyAttributeValue, AttributeValue, BooleanValue, DateValue, MetadataSelectOption, MetadataValue, ModelValue, NumericValue, RuleAttribute, RuleValue, RuleValueTypes, StringValue } from "../../../api/models";
import MetadataValueEditor from "./metadata_value";
import { useContext } from "react";
import MetadataSelectOptionEditor, { MetadataSelectOptionInfo } from "./metadata_select_option";
import dayjs, {Dayjs} from 'dayjs'
import RulesContext from "../context";

export interface Props {
    value: RuleValue;
    readOnly?: boolean;
    onUpdate?: (v:RuleValue) => void;
    disabledRuleValueTypes?: RuleValueTypes[] | undefined
}

const RuleValueEditor = (props: Props) => {
    const rulesConext = useContext(RulesContext);
    const onUpdateFn = props.onUpdate || ((_e: RuleValue) => { });

    function getValueDetailsReadOnly() {
        if (props.value instanceof BooleanValue) {
            return (<>
                {props.value.value ? "TRUE" : "FALSE"}
            </>);
        }
        else if (props.value instanceof StringValue) {
            return (<>{props.value.value}</>);
        }
        else if (props.value instanceof NumericValue) {
            return (<>{props.value.value}</>);
        }
        else if (props.value instanceof MetadataValue) {
            const categoryId = props.value.category;
            const category = rulesConext.categories.find(cat => cat.categoryId == categoryId)?.name || categoryId;
            return (<strong>
                {category}.{props.value.field}
            </strong>);
        }
        else if (props.value instanceof DateValue) {
            return (<>
                {props.value.date}
            </>);
        }
        else if (props.value instanceof AttributeValue) {
            return (
                <Select value={props.value.attribute} disabled={true}>
                    <Select.Option value={RuleAttribute.currentDate}>Current Date</Select.Option>
                    <Select.Option value={RuleAttribute.quoteCreatedDate}>Quote Created Date</Select.Option>
                    <Select.Option value={RuleAttribute.quoteProductionDate}>Quote Production Date</Select.Option>
                    <Select.Option value={RuleAttribute.firstTruckShipDate}>First Truck Ship Date</Select.Option>
                    <Select.Option value={RuleAttribute.quoteStateRegistration}>State Registration</Select.Option>
                    <Select.Option value={RuleAttribute.model}>Model</Select.Option>
                    <Select.Option value={RuleAttribute.modelYear}>Model Year</Select.Option>
                    <Select.Option value={RuleAttribute.quantity}>Quantity</Select.Option>
                    <Select.Option value={RuleAttribute.endCustomer}>End Customer</Select.Option>
                    <Select.Option value={RuleAttribute.destination}>Shipping Destination</Select.Option>
                    <Select.Option value={RuleAttribute.isOrder}>Is Order</Select.Option>
                    <Select.Option value={RuleAttribute.truckGvwr}>Truck GVWR</Select.Option>
                    <Select.Option value={RuleAttribute.cgPusher}>CGPUSHER (inch)</Select.Option>
                    <Select.Option value={RuleAttribute.cgTag}>CGTAG (inch)</Select.Option>
                </Select>
            );
        }
        else if (props.value instanceof AssemblyAttributeValue) {
            return (
                <Select value={props.value.attribute} disabled={true}>
                    <Select.Option value={AssemblyAttribute[AssemblyAttribute.categoryId]}>Category ID</Select.Option>
                    <Select.Option value={AssemblyAttribute[AssemblyAttribute.bom]}>BOM</Select.Option>
                </Select>
            );
        }
        else if (props.value instanceof MetadataSelectOption) {
            return (<>
                Selection Has Metadata
                {props.value.categoryId}.{props.value.field}.{props.value.value}
            </>);
        }
        else if (props.value instanceof ModelValue) {
            const modelId = props.value.modelId;
            return (<>
                Model <strong>{rulesConext.models.find(m => m.id == modelId)?.name || ('Model ID ' + modelId)}</strong>
            </>);
        }
        else {
            return (<></>);
        }
    }

    function getValueDetails() {
        if (props.value instanceof BooleanValue) {
            return (<>
                <Select value={props.value.value} onChange={(val) => {
                    const newValue = { ...props.value, value: val };
                    Object.setPrototypeOf(newValue, BooleanValue.prototype);
                    onUpdateFn(newValue);
                }} style={{ width: '100%' }}>
                    <Select.Option value={true}>True</Select.Option>
                    <Select.Option value={false}>False</Select.Option>
                </Select>
            </>);
        }
        else if (props.value instanceof StringValue) {
            return (<Input style={{ width: '100%' }} value={props.value.value} onChange={(e) => {
                const newValue = { ...props.value, value: e.target.value };
                Object.setPrototypeOf(newValue, StringValue.prototype);
                onUpdateFn(newValue);
            }} />);
        }
        else if (props.value instanceof NumericValue) {
            return (<InputNumber style={{ width: '100%' }} value={props.value.value} onChange={(val) => {
                const newValue = { ...props.value, value: val };
                Object.setPrototypeOf(newValue, NumericValue.prototype);
                onUpdateFn(newValue);
            }} />);
        }
        else if (props.value instanceof MetadataValue) {
            return (<MetadataValueEditor value={props.value} onUpdate={(value) => {
                onUpdateFn(value);
            }} />);
        }
        else if (props.value instanceof DateValue) {
            return (<div>
                <DatePicker value={props.value.date ? dayjs(props.value.date) : undefined} format={['MM/DD/YYYY', 'DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY']}
                    onChange={(newDate: Dayjs) => {
                        const newValue = new DateValue();
                        newValue.date = newDate.toISOString();
                        onUpdateFn(newValue);
                    }} />
            </div>);
        }
        else if (props.value instanceof AttributeValue) {
            return (
                <Select style={{ width: '100%' }} value={props.value.attribute} onChange={(val) => {
                    const newValue = { ...props.value, attribute: val };
                    Object.setPrototypeOf(newValue, AttributeValue.prototype);
                    onUpdateFn(newValue);
                }}>
                    <Select.Option value={RuleAttribute.currentDate}>Current Date</Select.Option>
                    <Select.Option value={RuleAttribute.quoteCreatedDate}>Quote Created Date</Select.Option>
                    <Select.Option value={RuleAttribute.quoteProductionDate}>Quote Production Date</Select.Option>
                    <Select.Option value={RuleAttribute.firstTruckShipDate}>First Truck Ship Date</Select.Option>
                    <Select.Option value={RuleAttribute.quoteStateRegistration}>State Registration</Select.Option>
                    <Select.Option value={RuleAttribute.model}>Model</Select.Option>
                    <Select.Option value={RuleAttribute.modelYear}>Model Year</Select.Option>
                    <Select.Option value={RuleAttribute.quantity}>Quantity</Select.Option>
                    <Select.Option value={RuleAttribute.endCustomer}>End Customer</Select.Option>
                    <Select.Option value={RuleAttribute.destination}>Shipping Destination</Select.Option>
                    <Select.Option value={RuleAttribute.isOrder}>Is Order</Select.Option>
                    <Select.Option value={RuleAttribute.truckGvwr}>Truck GVWR</Select.Option>
                    <Select.Option value={RuleAttribute.cgPusher}>CGPUSHER (inch)</Select.Option>
                    <Select.Option value={RuleAttribute.cgTag}>CGTAG (inch)</Select.Option>
                </Select>
            );
        }
        else if (props.value instanceof AssemblyAttributeValue) {
            return (
                <Select style={{ width: '100%' }} value={props.value.attribute} onChange={(val) => {
                    const newValue = { ...props.value, attribute: val };
                    Object.setPrototypeOf(newValue, AssemblyAttributeValue.prototype);
                    onUpdateFn(newValue);
                }}>
                    <Select.Option value={AssemblyAttribute[AssemblyAttribute.categoryId]}>Category ID</Select.Option>
                    <Select.Option value={AssemblyAttribute[AssemblyAttribute.bom]}>BOM</Select.Option>
                </Select>
            );
        }
        else if (props.value instanceof MetadataSelectOption) {
            return (<MetadataSelectOptionEditor value={props.value} onUpdate={(val: MetadataSelectOptionInfo) => {
                const newValue = { ...props.value, field: val.field, categoryId: val.categoryId, value: val.value };
                Object.setPrototypeOf(newValue, MetadataSelectOption.prototype);
                onUpdateFn(newValue);
            }} />);
        }
        else if (props.value instanceof ModelValue) {
            return (
                <Select style={{ width: '100%' }} value={props.value.modelId} onChange={(val) => {
                    const newValue = { ...props.value, modelId: val };
                    Object.setPrototypeOf(newValue, ModelValue.prototype);
                    onUpdateFn(newValue);
                }}>
                    {rulesConext.models.map(model => (<Select.Option key={model.id} value={model.id}>{model.name}</Select.Option>))}
                </Select>
            );
        }
        else {
            return (<></>);
        }
    }

    function onSelectValue(valueType: string) {
        if (valueType == props.value.type) return;

        if (valueType == RuleValueTypes.Boolean) {
            onUpdateFn(new BooleanValue());
        }
        else if (valueType == RuleValueTypes.AssemblyAttribute) {
            onUpdateFn(new AssemblyAttributeValue());
        }
        else if (valueType == RuleValueTypes.Metadata) {
            onUpdateFn(new MetadataValue());
        }
        else if (valueType == RuleValueTypes.MetadataSelectOption) {
            onUpdateFn(new MetadataSelectOption());
        }
        else if (valueType == RuleValueTypes.Numeric) {
            onUpdateFn(new NumericValue());
        }
        else if (valueType == RuleValueTypes.String) {
            onUpdateFn(new StringValue());
        }
        else if (valueType == RuleValueTypes.Date) {
            onUpdateFn(new DateValue());
        }
        else if (valueType == RuleValueTypes.Model) {
            onUpdateFn(new ModelValue());
        }
        else if (valueType == RuleValueTypes.Attribute) {
            onUpdateFn(new AttributeValue());
        }
    }


    if (props.readOnly) {
        return getValueDetailsReadOnly();
    }
    else {

        const RuleValueTypeLabels = {
          [ RuleValueTypes.Metadata ] : 'Metadata Value',
          [ RuleValueTypes.String ] : 'String Value',
          [ RuleValueTypes.Numeric ] : 'Numeric Value',
          [ RuleValueTypes.AssemblyAttribute ] : 'Assembly Attribute',
          [ RuleValueTypes.MetadataSelectOption ] : 'Metadata Select Option',
          [ RuleValueTypes.Boolean ] : 'Boolean',
          [ RuleValueTypes.Attribute ] : 'Attribute',
          [ RuleValueTypes.Date ] : 'Date',
          [ RuleValueTypes.Model ] : 'Model',
        }

        return (
            <Card>
                <Row justify="start">
                    <Col span={4}>
                        <span>Type: </span>
                    </Col>
                    <Col span={12}>
                      <Select style={{ width: '100%' }}
                        value={props.value.type} 
                        onSelect={onSelectValue} 
                        options={Object.values(RuleValueTypes)
                          .filter( rvt => !props.disabledRuleValueTypes?.includes( rvt ) )
                          .map(rvt => ({
                            label:RuleValueTypeLabels[ rvt ],
                            value:rvt,
                          }))}
                      />
                    </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                    <Col span={4}>
                        <span>Config: </span>
                    </Col>
                    <Col span={12}>
                        {getValueDetails()}
                    </Col>
                </Row >
            </Card >
        );
    }
};

export default RuleValueEditor;
