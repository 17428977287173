import "../../util/mobile-table.css";
import {
  notification,
  Button,
  Col,
  Row,
  Table,
  Space,
} from "antd";
import Title from "antd/lib/typography/Title";
import { useContext, useEffect, useState, useMemo } from "react";
import { ConfiguratorContext, S3UrlMapContext, UserListContext, UsersContext } from "../../context";
import { QuoteComment, CommentTopic } from "../../api/models"
import _ from "lodash";
import { useIntl } from "react-intl";
import { AsyncState, useAsyncState } from "../../hook/useAsyncState";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {QuoteCommentRequestOptions} from "../../api";
import QuoteCommentCard, {EditQuoteCommentCard} from "../QuoteCommentCard";
import { useQuoteContext } from "../../contexts/QuoteContext";
import BmMentions from "../BmMentions";
import Utils from "../../util/util";

const NOT_FOUND = -1;

export const getDefaultCommentTopics = (isAdminOrEngineering?:boolean) => {
  return ( isAdminOrEngineering )
    ? [CommentTopic.UserComment, CommentTopic.SystemActivity, CommentTopic.VfdReview ]
    : [CommentTopic.UserComment, CommentTopic.SystemActivity ];
 }

type S3Map = Record<string, string>
const QuoteCommentList = (props: {
  topics?: CommentTopic[]
  tags?:string[] | undefined
  hideSearch?:boolean
}) => {

  const configurator = useContext(ConfiguratorContext);
  const isAdminOrEngineering = configurator.isAdmin() || configurator.isEngineering();

  const DefaultTopics = props.topics || getDefaultCommentTopics(isAdminOrEngineering);

  const intl = useIntl();
  const [editCommentId, setEditCommentId] = useState<number | undefined>();
  const [showAddComment, setShowAddComment] = useState<boolean>(false);
  const { quoteAsync, adminView } = useQuoteContext();
  const quote = quoteAsync?.val;
  const [commentOptions, setCommentOptions] = useState<QuoteCommentRequestOptions | undefined>({
    showHidden: adminView,
    topic: DefaultTopics,
  });
  const [_s3UrlMap, s3UrlMapAsync] = useAsyncState<S3Map>();
  const [quoteCommentLst, quoteCommentLstAsync] = useAsyncState<QuoteComment[]>();
  const { userLstAsync } = useContext<UserListContext>(UsersContext);
  const [search, setSearch] = useState<string | undefined>();

  const s3UrlContext = useMemo(() => ({
    s3UrlMapAsync 
  }), [s3UrlMapAsync ]);

  useEffect(() => {
    setSearch(props.tags?.join(" "));
  }, [props.tags] );

  const handleShowAddComment = () => {
    setEditCommentId(undefined);
    setShowAddComment(true);
  }

  const handleEditComment = (commentId:number) => {
    setEditCommentId(commentId);
    setShowAddComment(false);
  }

  useEffect(() => {
    const quoteId = quote?.quoteId
    if ( quoteId?.length ) {
      loadS3Url(s3UrlMapAsync, quoteId, commentOptions);
      loadQuoteComments(quoteCommentLstAsync, quote?.quoteId, commentOptions)
        //update last viewed for all comments
        .then( comments => Promise.all( comments?.map( c => updateQuoteCommentLastViewed(quoteId, c.id) ) || [] ) );
    }
  }, [quote?.quoteId, commentOptions] );

  const loadS3Url = async (s3UrlMapAsync:AsyncState<S3Map>, quoteId:string | undefined, options?:QuoteCommentRequestOptions): Promise<S3Map | undefined> => {
    if ( !quoteId?.length ) return;

    s3UrlMapAsync.isLoading();
    try {
      const resp = await configurator.api.fetchQuoteCommentDocumentUrls( quoteId, options );
      s3UrlMapAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch document urls. " + errorMsg });
      s3UrlMapAsync.setFail(e.message);
    }
    return;
  }

  const loadQuoteComments = async (quoteCommentLstAsync:AsyncState<QuoteComment[]>, quoteId:string | undefined,  options?:QuoteCommentRequestOptions) : Promise<QuoteComment[] | undefined> => {
    if ( !quoteId ) return;

    quoteCommentLstAsync.isLoading();
    try {
      const resp = await configurator.api.fetchQuoteComments(quoteId, options);
      quoteCommentLstAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch document urls. " + errorMsg });
      quoteCommentLstAsync.setFail(e.message);
    }
    return;
  }

  const updateQuoteCommentLastViewed = async (quoteId:string, commentId:number) : Promise<QuoteComment | undefined> => {
    if ( !quoteId ) return;

    try {
      const resp = await configurator.api.updateQuoteCommentLastViewed(quoteId, commentId);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update quote comment last viewed. " + errorMsg });
    }
    return;
  }

  const handleAddedComment = (comment:QuoteComment) => {
    const lst = [ comment ].concat( quoteCommentLst || [] );
    quoteCommentLstAsync.setDone( lst );
    setShowAddComment(false);
  }

  const handleEditedComment = (comment:QuoteComment) => {
    const ndx = quoteCommentLst?.findIndex( c => c.id === comment.id ) ?? NOT_FOUND;
    if ( ndx === NOT_FOUND ) return;

    const lst = [...(quoteCommentLst || [])];

    if ( comment.hidden ) {
      lst.splice( ndx, 1 );
    }
    else {
      lst.splice( ndx, 1, comment );
    }
    quoteCommentLstAsync.setDone( lst );
    setEditCommentId(undefined);
  }

  const handleToggleComments = () => {
    const topic = _.isEqual( commentOptions?.topic, [ CommentTopic.UserComment ] ) ? DefaultTopics : [CommentTopic.UserComment];
    setCommentOptions( {...commentOptions, topic } );
  }

  const handleToggleActivities = () => {
    const topic = _.isEqual( commentOptions?.topic, [ CommentTopic.SystemActivity ] ) ? DefaultTopics : [CommentTopic.SystemActivity];
    setCommentOptions( {...commentOptions, topic } );
  }

  const handleToggleVfd = () => {
    const topic = _.isEqual( commentOptions?.topic, [ CommentTopic.VfdReview ] ) ? DefaultTopics : [CommentTopic.VfdReview];
    setCommentOptions( {...commentOptions, topic } );
  }

  const isAddCommentsVisible = ( commentOptions?.topic?.includes( CommentTopic.UserComment ) && !showAddComment );
  const isActivitiesVisible = (commentOptions?.topic?.includes(CommentTopic.SystemActivity) && !showAddComment);
  const isVfdVisible = commentOptions?.topic?.includes(CommentTopic.VfdReview);
  const activitiesTitleStyle = { fontWeight: isActivitiesVisible  ? 700 : 400 }
  const commentTitleStyle = { fontWeight: isAddCommentsVisible ? 700 : 400 }
  const vfdTitleStyle = { fontWeight: isVfdVisible ? 700 : 400 }

  const datasource = quoteCommentLst
    ?.filter( c => {
      if ( !search ) return true;
      const searchFilter = Utils.splitByWhitespacePreservingQuotes(search);
      const searchTxt = [c.text].concat(c.tags?.flat()).join(" ");
      return Utils.searchValue( searchFilter, searchTxt );
    })
    .sort((a,b) => dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1);
  const pageSize = 7;
  const pagination = ((datasource?.length || 0) > pageSize) ? { pageSize } : false; 

  const topics = DefaultTopics
  .map( t => {
    if( t === CommentTopic.UserComment ) {
      return <Button type="text" ghost onClick={handleToggleComments}><Title level={5} style={commentTitleStyle}>Comments</Title></Button> 
    }
    if( t === CommentTopic.SystemActivity ) {
      return <Button type="text" ghost onClick={handleToggleActivities}><Title level={5} style={activitiesTitleStyle}>Activities</Title></Button>
    }
    if( t === CommentTopic.VfdReview ) {
      return <Button type="text" ghost onClick={handleToggleVfd}><Title level={5} style={vfdTitleStyle}>VFD</Title></Button> 
    }
    return <></>;
  })
  .reduce((acc,v) => {
    if ( v ) {
      return <>{acc}|{v}</>
    }
    return v;
  });

  return <>
    <S3UrlMapContext.Provider value={s3UrlContext}>

      <Space direction="vertical" style={{width: "100%"}}>

      <Row justify={"space-between"} style={{marginRight: ".4rem"}} align="middle">
        <Col>{topics}</Col>
        {!showAddComment && isAddCommentsVisible  && 
        <Col>
          <Button icon={<PlusOutlined />} shape="circle" type="primary" size="small"
            onClick={handleShowAddComment}
          />
        </Col>
        }
      </Row>

      {(!props.hideSearch && !showAddComment) &&
        <div style={{margin: "0px 0px 0px 8px", padding: "0px 8px 0px 0px"}} >
          <BmMentions 
            allowClear
            rows={1}
            value={search} 
            placeholder="Search Comments" 
            onChange={(txt) => setSearch( txt)} 
            mentions={{
              '@': userLstAsync?.val?.map( u => ({value: _.upperFirst(_.camelCase( u.name)), label: u.name })),
              '#': props.tags?.map( t => ({value: _.upperFirst(_.camelCase( t )), label: t }))
            }}
          /> 
        </div> }

      {showAddComment &&
      <EditQuoteCommentCard 
        quoteId={quote?.quoteId}
        comment={{
          topic: CommentTopic.UserComment
        }}
        reset={showAddComment}
        onCancel={() => setShowAddComment(false)}
        onSave={handleAddedComment}
      />}

      <Table
        className="mobile-table"
        size="small"
        bordered={false}
        rowKey="id"
        loading={quoteCommentLstAsync.isLoading()}
        dataSource={datasource}
        pagination={pagination}
        columns={[{ render: (comment) => 
          (editCommentId === comment.id ) 
            ? <EditQuoteCommentCard comment={comment} 
              quoteId={quote?.quoteId}
              onCancel={() => setEditCommentId(undefined)}
              onSave={handleEditedComment}
            /> 
            : <QuoteCommentCard comment={comment} 
              onEdit={handleEditComment} 
            />
        }]}
      />
      </Space>

    </S3UrlMapContext.Provider>
  </>
}

export default QuoteCommentList;


