import { InputNumber, InputNumberProps, } from "antd";


export const currencyFormatter = (value:string | number | undefined) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const CurrencyInputNumber = (props:InputNumberProps) => {
  return <InputNumber
    {...props}
    formatter={currencyFormatter}
    parser={value => value!.replace(/\$\s?|(,*)/g, '')}
  />
}

export default CurrencyInputNumber;

