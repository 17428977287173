import Title from "antd/lib/typography/Title";
import { Table, Space, notification,  Input, Button, Form, Result, Descriptions } from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext, } from "../context";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { BaseQuote, Dealer, SalesTeam, User, UserSalesTeam } from "../api/models";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import {debounce} from "lodash";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import {SortOrder} from "antd/es/table/interface";
import BMButton, { BMButtonProps } from "../components/BMButton";
import ModalWizard from "../components/ModalWizard";
import SelectQuoteBySalesTeamList, { QuoteSelection } from "../components/SelectQuoteBySalesTeamList";
import Wizard, { getWizardFooter, getWizardTitle, WizardInstance, WizardStep } from "../components/Wizard";
import SelectDealerBySalesTeamList, { DealerSelection } from "../components/SelectDealerBySalesTeamList";
import UserSelection from "../components/UserSelection";
import { SalesTeamRequest } from "../api";
import SalesTeamForm, { SalesTeamFormValues } from "../components/Quote/SalesTeamForm";
import Utils from "../util/util";

type  SortResult<T> = SorterResult<T> | SorterResult<T>[]

interface SalesTeamFilter {
  search?: string
}

const DEFAULT_PAGE_SIZE = 20;
const UserSalesTeamsPage = () => {
  const isMobile = useCheckMobileScreen();
  const intl = useIntl();

  const [_salesTeamLst, salesTeamLstAsync] = useAsyncState<UserSalesTeam[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string|undefined|null>("search", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number|undefined|null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number|undefined|null>("p", NumberParam);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [filter, setFilter] = useState<SalesTeamFilter>({
    search: searchFilterParam || undefined, //silly fix for null
  });

  const defaultSort = {
    columnKey: "user.name",
    order: 'ascend' as SortOrder
  };
  const [sort, setSort] = useState<SortResult<UserSalesTeam>>({
    columnKey: sortFieldQueryParam || defaultSort.columnKey,
    order: sortDirectionQueryParam as ( SortOrder | undefined ) || defaultSort.order
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });

  //change in filters should trigger refetch
  useEffect(() => {
    reloadSalesTeams();
  }, [ filter, sort  ]);

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current ]);

  const reloadSalesTeams = () => {
      loadSalesTeams( salesTeamLstAsync, pagination, filter , sort );
  }

  const loadSalesTeams = useCallback(debounce( async (salesTeamLstAsync:AsyncState<UserSalesTeam[]>, pagination: TablePaginationConfig, filter: SalesTeamFilter, sorter:SortResult<UserSalesTeam> ) => {

    var sort = [ sorter ].flat()[0];
    salesTeamLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchUserSalesTeamList({
        ...filter,
        page: ( pagination.current || 1 ) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        sort: {
          field: sort.columnKey?.toString() ||  defaultSort.columnKey,
          direction: sort.order == 'ascend' ? 'asc' : 'desc',
        }
      })
      salesTeamLstAsync.setDone( resp.data.content );
      setPagination({ ...pagination, total: resp.data.totalElements });
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message })
      notification.error({message:"Sales Teams failed to load. " + errorMsg});
      salesTeamLstAsync.setFail( e.message );
    }

  }, 400 ), [] );


  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: SortResult<UserSalesTeam>) => {

    if ( sorter != sort ) {
      const firstSort = [ sort ].flat()[0];
      setSortFieldQueryParam( firstSort.columnKey?.toString() );
      setSortDirectionQueryParam( firstSort.order );
      setSort(sorter);
      setCurrentPageParam(1);
      setPagination({
        current: 1,
        ...pagination
      });
    }
    else {
      setPagination(pagination);
    }
  };

  const handleChangeSearch = (search:string) => {
    setSearchFilterParam(search);
    setFilter({
      search
    });
  }

  const handleEditSalesTeam = () => {
    reloadSalesTeams();
  }

  const handleAddSalesTeam = () => {
    reloadSalesTeams();
  }

  const columns:ColumnType<UserSalesTeam>[] = [
    {
      title: "",
      render: (ust:UserSalesTeam) => <EditUserSalesTeamButtonModal type="text" className="ghostBmButton" 
        value={ust}
        onChange={handleEditSalesTeam}
      >
        <span style={{textDecoration: "underline"}}>{Utils.formatUsername(ust.user)}</span>
      </EditUserSalesTeamButtonModal>
        
    },
    {
      title: "Additional Sales",
      render: (ust:UserSalesTeam) => ust.salesTeam?.sales?.map(u => u.name ).join(", ")
    },
    {
      title: "Engineering",
      render: (ust:UserSalesTeam) => ust.salesTeam?.engineers?.map(u => u.name ).join(", ")
    },
    {
      title: "Support",
      render: (ust:UserSalesTeam) => ust.salesTeam?.support?.map(u => u.name ).join(", ")
    },
  ];

  return (
    <div className="site-layout-background">
      <Space direction="vertical" size="small" style={{ display: 'flex' }}>

        <div style={{width: "100%", display: "flex", justifyContent:"space-between", padding: "0rem .3rem 0rem .3rem" }}>
          <Title level={2}>Sales Teams</Title>
          <EditUserSalesTeamButtonModal type="primary" onChange={handleAddSalesTeam} >New</EditUserSalesTeamButtonModal>
        </div>

        <div style={{width: "100%"}}>
        <Input defaultValue={searchFilterParam || undefined} onChange={(e) => handleChangeSearch( e.target.value)} placeholder="Search teams" allowClear />
        </div>

        <Table
          loading={salesTeamLstAsync.isLoading()}
          onChange={tableOnChange}
          bordered
          pagination={pagination}
          dataSource={salesTeamLstAsync.val}
          columns={columns}
          rowKey="id"
        />

      </Space>
    </div>
  );
};

const EditUserSalesTeamButtonModal = (props: Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  value?: UserSalesTeam | undefined
  onChange?:(d:UserSalesTeam | undefined) => void
  onCancel?:() => void
  open?:boolean
}) => {

  const {value:a, onChange:b, open:c, ...btnProps} = props;

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState<boolean>(props.open || false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [selectedSalesTeam, selectedSalesTeamAsync] = useAsyncState<SalesTeam>();

  const [_activeKey, setActiveKey] = useState<React.Key>();

  //note: reference used because steps change visibility
  const wizardInstance = useRef<WizardInstance>();

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    wizardInstance.current = instance;
  };

  const createSalesTeam = async () : Promise<SalesTeam | undefined> => {
    if ( !selectedUser?.id ) return;

    selectedSalesTeamAsync.setLoading();
    try {
      const resp = await configurator.api.createUserSalesTeam(selectedUser.id )
      selectedSalesTeamAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to create sales Team. " + errorMsg;
      notification.error( { message: msg });
    selectedSalesTeamAsync.setFail(msg);
    }

    return;
  }

  const handleSelectUser = () => {
    createSalesTeam().then( st => {
      if ( st ) {
        setTimeout( () => wizardInstance.current?.nextStep(), 500 );
      }
    });
  }

  const handleChangeSalesTeam = (userSalesTeam:UserSalesTeam | undefined) => {
    selectedSalesTeamAsync.setDone(userSalesTeam?.salesTeam);
    setTimeout( () => wizardInstance.current?.nextStep(), 500 );
  }

  const handleChange = () => {
    if ( !selectedUser ) return;

    setIsOpen(false);
    const userSalesTeam:UserSalesTeam = {
      user: selectedUser, 
      salesTeam: selectedSalesTeam
    };
    props.onChange?.(userSalesTeam);
  }

  const handleCancel = () => {
    setIsOpen(false);
    props.onCancel?.();
  }

  const primarySalesId = selectedUser?.id;

  return <>
    <BMButton {...btnProps}
      onClick={() => setIsOpen(true)} 
    />
    <ModalWizard
      open={isOpen}
      width={"50rem"}
      onCancel={handleCancel}
      showSteps={false}
      onStep={handleStep}
      afterOpenChange={(open) => {
        if(open) {
          setSelectedUser(props.value?.user);
          selectedSalesTeamAsync.setDone(props.value?.salesTeam);
        }}}
      steps={[
        {
          key:"selectUser",
          hidden: !!props.value?.user,
          title: "Select User",
          body:(_nav) => <div>
            <UserSelection value={selectedUser?.id} onChange={setSelectedUser} />
          </div>,
          footer:(nav) => <div style={{display: "flex", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="cancel" onClick={handleCancel}>Cancel</Button>
              <Button key="next" type="primary" loading={selectedSalesTeamAsync.isLoading()} onClick={handleSelectUser}>Next</Button>
          </div>
        },
        useEditUserSalesTeamStep({
          key:1,
          value: {
            user: selectedUser as User,
            salesTeam: selectedSalesTeam
          },
          onChange: handleChangeSalesTeam,
          onCancel: handleCancel
        }),
        useUpdateDealerSalesTeamsStep( {
          key:2,
          primarySalesId,
          onChange: () => wizardInstance.current?.nextStep(),
          onCancel: () => wizardInstance.current?.prevStep()
        }),
        useUpdateQuoteSalesTeamsStep( {
          key:3,
          primarySalesId,
          onChange: handleChange,
          onCancel: () => wizardInstance.current?.prevStep()
        }),
      ] }
    />
  </>;
}

const useUpdateQuoteSalesTeamsStep = (props:{
  key: React.Key
  hidden?: boolean
  primarySalesId: string | undefined
  onChange?:(q?:number[] | undefined) => void
  onCancel?:() => void
}) : WizardStep => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [quoteLst, setQuoteLst] = useState<QuoteSelection>();
  const [_updateQuotes, updateQuotesAsync] = useAsyncState<BaseQuote[]>([]);

  const updateQuotes = async (primarySalesId:string, values: number[] | undefined) : Promise<void> => {

    updateQuotesAsync.setLoading();
    try {
      await configurator.api.refreshQuoteSalesTeam(primarySalesId, values );
      updateQuotesAsync.setDone();
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Error updating quotes.  " + errorMsg });
      updateQuotesAsync.setFail(errorMsg);
    }

    return;
  };

  const handleUpdateQuotes = async (nav:WizardInstance) => {
    if ( !props.primarySalesId ) return;

    if ( quoteLst?.selections.length ) {

      const lst = quoteLst?.type === "all" ? undefined : quoteLst?.selections;
      await updateQuotes(props.primarySalesId, lst);

      nav.nextStep();
      props.onChange?.(lst);
    }
    else {
      nav.nextStep();
      props.onChange?.([]);
    }

  }

  const handleCancel = (nav:WizardInstance) => {

    nav.nextStep();
    props.onCancel?.();
  }

  return {
    key: props.key,
    hidden: props.hidden,
    title: "Update Quotes",
    body: () =>  <div key="quoteUpdate" >
      <div style={{marginBottom: ".4rem"}}>Select quotes to update with latest sales team.</div>
      <SelectQuoteBySalesTeamList 
        primarySalesId={props.primarySalesId} 
        onChange={setQuoteLst}
      />
    </div>,
    footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
      <Button type="primary" loading={updateQuotesAsync.isLoading()} onClick={() => handleUpdateQuotes(nav)}>Update</Button>
      <Button onClick={() => handleCancel(nav)}>Back</Button>
    </div>

  };
}

const useUpdateDealerSalesTeamsStep = (props:{
  key: React.Key
  hidden?: boolean
  primarySalesId: string | undefined
  onChange?:(q?:string[] | undefined) => void
  onCancel?:() => void
}) : WizardStep => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [dealerLst, setDealerLst] = useState<DealerSelection>();
  const [_updateDealers, updateDealersAsync] = useAsyncState<Dealer[]>([]);

  const updateDealers = async (primarySalesId:string, values: string[] | undefined) : Promise<void> => {

    updateDealersAsync.setLoading();
    try {
      await configurator.api.refreshDealerSalesTeam(primarySalesId, values );
      updateDealersAsync.setDone();
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Error updating dealers.  " + errorMsg });
      updateDealersAsync.setFail(errorMsg);
    }

    return;
  };

  const handleUpdateDealers = async (nav:WizardInstance) => {
    if ( !props.primarySalesId ) return;

    if ( dealerLst?.selections.length ) {

      const lst = dealerLst?.type === "all" ? undefined : dealerLst?.selections;
      await updateDealers(props.primarySalesId, lst);

      nav.nextStep();
      props.onChange?.(lst);
    }
    else {
      nav.nextStep();
      props.onChange?.([]);
    }

  }

  const handleCancel = (nav:WizardInstance) => {

    nav.nextStep();
    props.onCancel?.();
  }

  return {
    key: props.key,
    hidden: props.hidden,
    title: "Update Dealers",
    body: () =>  <div key="dealerUpdate" >
      <div style={{marginBottom: ".4rem"}}>Select dealers to update with latest sales team.</div>
      <SelectDealerBySalesTeamList 
        primarySalesId={props.primarySalesId} 
        onChange={setDealerLst}
      />
    </div>,
    footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
      <Button type="primary" loading={updateDealersAsync.isLoading()} onClick={() => handleUpdateDealers(nav)}>Update</Button>
      <Button onClick={() => handleCancel(nav)}>Back</Button>
    </div>

  };
}

const useEditUserSalesTeamStep = (props: {
  key: React.Key
  hidden?: boolean
  value: UserSalesTeam | undefined
  onChange?:(t:UserSalesTeam | undefined) => void
  onCancel?:() => void
}) : WizardStep => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const [form] = Form.useForm();

  const [_selectedSalesTeam, selectedSalesTeamAsync] = useAsyncState<SalesTeam>();
  const [activeKey, setActiveKey] = useState<React.Key>();
  const [initialValues, setInitialValues] = useState<SalesTeamFormValues>();

  useEffect(() => {
    setInitialValues(props.value?.salesTeam);
  }, [props.value] );


  useEffect(() => {
    form.resetFields();
  }, [initialValues] );

  //note: reference used because steps change visibility
  const wizardInstance = useRef<WizardInstance>();

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    wizardInstance.current = instance;
  };

  const handleCancel = () => {
    props.onCancel?.();
  }

  const handleSave = async () => {
    if( !props.value ) return;

    try {
      const values = await form.validateFields() as SalesTeamFormValues;
      const team = {
        id: values.id,
        name: values.name,
        sales: values.sales?.map( u => u.id ),
        engineers: values.engineers?.map( u => u.id ),
        support: values.support?.map( u => u.id ),
      };

      const saved =  await editSalesTeam(team) ;

      if ( saved ) {
 
        const userSalesTeam = {
          ...props.value,
          salesTeam:saved
        }
        props.onChange?.(userSalesTeam);
      }
    }
    catch(e:any) {
      notification.error({message: "Please fix validation errors." });
    }
  }

  const handleConfirmDelete = async () => {
    if ( !props.value?.user ) return;

    const rslt = await deleteSalesTeam(props.value.user.id);

    if ( rslt ) {
      props.onChange?.(props.value);
    }
  }

  const editSalesTeam = async (salesTeam:SalesTeamRequest) : Promise<SalesTeam | undefined> => {

    selectedSalesTeamAsync.setLoading()
    try {
      const resp = await configurator.api.updateSalesTeam(salesTeam)
      selectedSalesTeamAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to edit sales Team. " + errorMsg;
      notification.error( { message: msg });
      selectedSalesTeamAsync.setFail(msg);
    }

    return;
  }

  const deleteSalesTeam = async (userId:string) : Promise<boolean> => {

    try {
      await configurator.api.deleteUserSalesTeam(userId)
      return true;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to delete sales Team. " + errorMsg;
      notification.error( { message: msg });
    }

    return false;
  }

  const steps:WizardStep[] = [
        {
          key:1,
          title: props.value ? "Edit " + (props.value.salesTeam?.name || "" ) + " Sales Team" : "Add Sales Team",
          body: () => <div key="formStep">
            <SalesTeamForm form={form} initialValues={initialValues} />
          </div>,
          footer:(nav) => <div style={{display: "flex", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
            {props.value &&
              <Button danger key="delete" onClick={() => nav.nextStep()}>Delete</Button>
            }
            <div style={{display: "lex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="cancel" onClick={handleCancel}>Cancel</Button>
              <Button key="save" type="primary" onClick={() => handleSave()}>Save</Button>
            </div>
          </div>
        },
        {
          key:2,
          body: () =>  <div key="confirmStep" >
            <ConfirmDeleteSalesTeamResult value={props.value?.salesTeam} />
          </div>,
          footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button type="primary" danger onClick={() => handleConfirmDelete()}>Delete</Button>
            <Button onClick={() => nav.prevStep()}>Back</Button>
          </div>
        }
  ];

  const activeStep = steps?.find( s => s.key === activeKey );
  const title = getWizardTitle( wizardInstance.current, activeStep);
  const footer = getWizardFooter( wizardInstance.current, activeStep);

  return {
    key: props.key,
    hidden: props.hidden,
    body: () => 
      <Wizard 
        key="editSalesTeam"
        steps={steps} 
        showSteps={false}
        onStep={handleStep}
      />,
    title: () => title,
    footer: () => footer,
  }

}

const ConfirmDeleteSalesTeamResult = (props:{
  value:SalesTeam | undefined
}) => {
  return  <Result
    status="warning"
    title="The following sales Team will be deleted:"
    style={{padding: "0"}}
  >
    <Descriptions
      column={1}
      className="dialog-salesTeam"
      items={ [ {
        label: "Name",
        children: props.value?.name
      },
      {
        label: "Sales",
        children: props.value?.sales?.map(u => u.name ).join(", ")
      },
      {
        label: "Engineering",
        children: props.value?.engineers?.map(u => u.name ).join(", ")
      },
      {
        label: "Support",
        children: props.value?.support?.map(u => u.name ).join(", ")
      },
      ]}
      size="small"
    />
  </Result>

}




export default UserSalesTeamsPage;
