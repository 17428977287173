import "../util/mobile-table.css";
import { 
  Button,
  Upload,
  Modal,
  Result,
  ButtonProps,
} from "antd";
import  {useState } from "react";
import { UploadOutlined } from "@ant-design/icons";

const UploadFileBtn = (props: Omit<ButtonProps, 'title'> & {
  url:string,
}) => {

  interface ImportFailure {
    id:string, 
    message:string
  }
  enum UPLOADING_STATUS {
    INIT = "init",
    ERROR = "error",
    DONE = "done",
    UPLOADING = "uploading"
  }

  const { url, children, ...btnProps } = props;

  const [uploadFile, setUploadFile] = useState<{status:string, response?: ImportFailure[]}>({status: UPLOADING_STATUS.INIT});

  const onUploadChange = ({file}) => {
    setUploadFile( file );
    return file.response;
  };
  const isUploading = UPLOADING_STATUS.UPLOADING == uploadFile.status;

  return <>
    <Upload
      name="file"
      action={url}
      onChange={onUploadChange}
      withCredentials={true}
      showUploadList={false}
    >
      <Button {...btnProps}
        icon={<UploadOutlined />}
        loading={isUploading}
      >
        {children}
      </Button>
    </Upload>
    <Modal 
      open={uploadFile.status == UPLOADING_STATUS.DONE || uploadFile.status === UPLOADING_STATUS.ERROR}
      onOk={() => setUploadFile({status: UPLOADING_STATUS.INIT})}
      onCancel={() => setUploadFile({status: UPLOADING_STATUS.INIT})}
      cancelButtonProps={{style :{display : 'none'}}}
    >
      <Result
        status={uploadFile.status == UPLOADING_STATUS.DONE ? "success" : "error"}
        title={uploadFile.status == UPLOADING_STATUS.DONE ? "Upload Successful" : "Error"}
        subTitle={ !!uploadFile?.response?.length && <div style={{textAlign: "left"}}>
          <div>The following records had errors:</div>
          <ul>
            {uploadFile?.response?.map( (err,ndx) => <li key={`upload-msg-${ndx}`}>{err.message}</li>)}
          </ul>
        </div> }
      />
    </Modal>
  </>;
};

export default UploadFileBtn;

