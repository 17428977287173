import { debounce } from 'lodash';
import { useCallback, useContext, useEffect } from 'react';
import { RequestPurchaseOrder } from '../api';
import { ConfiguratorContext } from '../context';

const DocumentSignUI = (props:{
  quoteRevisionId:number | undefined
  details:RequestPurchaseOrder | undefined
  onLoad?:(l:boolean) => void
  onDone:() => void
}) => {

  const configurator = useContext(ConfiguratorContext);

  const handleDocusignReady = (event:any) => {
    console.log('UI is rendered', event);
  };
  const handleLoading = (l:boolean) => {
    props.onLoad?.(l);
  };
  const handleDocusignSessionEnd = (_event:any) => props.onDone();

  const loadDocuSign = useCallback(debounce( async (quoteRevisionId:number | undefined, details:RequestPurchaseOrder | undefined, loading:(b:boolean) => void, ready:(e:any) => void, sessionEnd:(e:any) => void) : Promise<void> => {
    if ( !quoteRevisionId ) return;
    if ( !details) return;

    loading(true);
    try {
      const config  = await configurator.api.loadDocusignConfiguration();
      const integrationKey = config.data.integrationKey;

      //@ts-ignore
      const docusign = await window.DocuSign.loadDocuSign(integrationKey);

      const resp = await configurator.api.createDocusignView( quoteRevisionId, details );
      const docUrl = resp.data;

      const url = `${docUrl}&output=embed`;
      console.log( url );
      loading(false);

      const signing = docusign.signing({
        url,
        displayFormat: 'focused',
      });

      signing.on('ready',ready);
      signing.on('sessionEnd', sessionEnd);

      signing.mount('#agreement');

    } catch (ex) {
      loading(false);
      console.error(ex);
      // Handle error
      console.log ("### Error calling docusign.js");
      console.log (ex);              
    }
  }, 1000), []);

  useEffect(() => {
    props.onLoad?.(true);
    loadDocuSign(props.quoteRevisionId, props.details, handleLoading, handleDocusignReady, handleDocusignSessionEnd);
  }, []);

  return <>
    <style>
      {`
      .docusign-agreement-container {
      height: 90vh;
      width: 980px;
      `}

    </style>
    <div className="docusign-agreement-container" id="agreement" />
  </>
}

export default DocumentSignUI;
