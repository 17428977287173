import { ProCard, ProForm, ProFormCheckbox, ProFormDatePicker, ProFormInstance, ProFormList, ProFormText } from "@ant-design/pro-components";
import { Button, Col, Divider, InputNumber, Modal, notification, Row, Select, Spin, Tooltip } from "antd";
import { useContext, useEffect } from "react";
import { ConfiguratorContext } from "../../context";
import { InputDateTimeFormat, MasterScheduleEvent, MONTHS, ProductionLineWithEvents } from "../../api/models";
import { SaveOutlined, CopyOutlined, ExclamationCircleFilled, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useAsyncState } from "../../hook/useAsyncState";
import dayjs, { Dayjs } from "dayjs";
import { debounce, isArray } from "lodash";
import Utils from "../../util/util";
import { useIntl } from "react-intl";
import { ALL_LINES } from "./master_schedule_slots";

interface AllProductionLineEventsProps {
  productionLines: string[];
  refresh: boolean;
  showDefaultEvent: boolean;
  showAllEvents: boolean
  setRefresh: (refresh: boolean) => void;
  selectedMonth: Dayjs;
  setSelectedMonth: (date: Dayjs) => void;
}

const years = Array.from({ length: 20 }, (_, i) => dayjs().year() - 10 + i);

const AllProductionLineEvents = (props: AllProductionLineEventsProps) => {
  const configurator = useContext(ConfiguratorContext);
  const { productionLines, refresh, showDefaultEvent, setRefresh, selectedMonth, setSelectedMonth, showAllEvents } = props;
  const [lineWithEvents, LineWithEventsAsyncState] = useAsyncState<ProductionLineWithEvents[]>();
  const intl = useIntl();
  
  const [line1Form] = ProForm.useForm();
  const [line2Form] = ProForm.useForm();
  const [line3Form] = ProForm.useForm();
  const formInstances = [line1Form, line2Form, line3Form];

  useEffect(() => {
    populateForms(lineWithEvents || [], formInstances);
  }, [showDefaultEvent, showAllEvents, selectedMonth]);

  const isWithinMonth = (monthDate: Dayjs, startDate: Date | undefined, endDate: Date | undefined): boolean => {
    if (!monthDate || !startDate) return false;

    const startOfMonth = monthDate.utc().startOf("month");
    const endOfMonth = monthDate.utc().endOf("month");

    const eventStart = dayjs(startDate).utc();
    const eventEnd = endDate ? dayjs(endDate).utc() : null;

    return eventEnd
        ? (eventEnd.isAfter(startOfMonth, "day") && eventStart.isBefore(endOfMonth, "day"))
          || eventEnd.isSame(startOfMonth, "day") 
          || eventStart.isSame(endOfMonth, "day") 
        : eventStart.isBefore(endOfMonth, "day") || eventStart.isSame(endOfMonth, "day");
};

  const mapEventsToInitialValues = (events: MasterScheduleEvent[], productionLine: string) => 
    events.filter(e => !showDefaultEvent || e.isDefault).filter(e => showAllEvents || !selectedMonth || isWithinMonth(selectedMonth, e.startDate, e.endDate)).map((event) => ({
      id: event.id,
      name: event.name,
      productionLine,
      iceTaktRate: event.iceTaktRate || 0,
      bevTaktRate: event.bevTaktRate || 0,
      startDate: event.startDate ? dayjs(event.startDate) : null,
      endDate: event.endDate ? dayjs(event.endDate) : null,
      isDefault: event.isDefault || false,
    }));

  const populateForms = (lineWithEvents: ProductionLineWithEvents[], formInstances: ProFormInstance[]) => {
    lineWithEvents.sort((a, b) =>a.productionLine.localeCompare(b.productionLine))
    .forEach((line, index) => {

      if (index < formInstances.length) {
        const form = formInstances[index];
        form.setFieldsValue({
          productionLineList: mapEventsToInitialValues(line.events, line.productionLine),
        });
      }
    });
  };

  useEffect(() => {
    getAllEvents();
  }, [productionLines, refresh]);

  const getAllEvents = async () => {
    if (productionLines?.length === 0) return;
    LineWithEventsAsyncState.setLoading();
    try {
      const lines = productionLines.includes("All") ? ALL_LINES : productionLines;
      const resp = await configurator.api.getAllMasterScheduleEvents(lines);
      LineWithEventsAsyncState.setDone(resp.data);
      populateForms(resp.data, formInstances);
    } catch (e: any) {
      console.log(e);
    }
  };

  const getFormDate = (data: any) => {
    const startDate = data.startDate?.format(InputDateTimeFormat);
    const endDate = data.endDate
    ? dayjs(data.endDate).format(InputDateTimeFormat)
    : data.startDate
    ? dayjs(data.startDate).add(1, 'day').format(InputDateTimeFormat)
    : null;
    const isDefault = data.isDefault;

    return {
      iceTaktRate: Number(data.iceTaktRate),
      bevTaktRate: Number(data.bevTaktRate),
      startDate: startDate,
      endDate: isDefault ? data.endDate : endDate,
    }
  }

  const verifyCurrentEvent = (startDate: Date | undefined, endDate: Date | undefined) => {
    if (dayjs().isAfter(startDate, "day") || dayjs().isSame(startDate, "day")) {
      return new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm Action",
          content: "This event affects the previous takt rate. Do you want to continue?",
          icon: <ExclamationCircleFilled style={{ color: "#1677FF" }} />, 
          onOk: () => {
            resolve(true);
          },
          onCancel: () => {
            reject(false);
          },
          okText: "Confirm",
          cancelText: "Cancel",
        });
      });
    }
    return Promise.resolve(); // Resolve immediately if not in range
  };

  const debouncedSave = debounce((data) => {
    if (data.id) updateMasterScheduleEvent(data);
    else createMasterScheduleEvent(data);
  }, 500);

  const handleSave = (formIndex: number, itemIndex: number) => {
    const formInstance = formInstances[formIndex];
    const productionLineData = formInstance?.getFieldValue('productionLineList');
    const itemToSave = productionLineData?.[itemIndex];

    formInstance?.validateFields().then(() => {
      const { startDate, endDate } = itemToSave;
  
      if (itemToSave.id) {
        verifyCurrentEvent(startDate?.toDate(), endDate?.toDate())
        .then(() => {
          debouncedSave({
            ...itemToSave,
          });
        })
        .catch(() => {
          notification.info({ message: "Save action was canceled." });
        });
      }
      else {
        debouncedSave({
          ...itemToSave,
        });
      }
    });
  }

  const updateMasterScheduleEvent = async (itemToSave: any) => {
    try {
      await configurator.api.updateMasterScheduleEvent({
        ...itemToSave,
        ...getFormDate(itemToSave),
      });

      notification.success( { message: "Event updated. " });

      getAllEvents();
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data?.message || e.message });
      notification.error( { message: "Failed to update master schedule event. " + errorMsg });
    }
  }

  const createMasterScheduleEvent = async (itemToSave: any) => {
    try {
      await configurator.api.createMasterScheduleEvent({
        ...itemToSave,
        ...getFormDate(itemToSave),
      });

      notification.success( { message: "Event created. " });

      getAllEvents();
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data?.message || e.message });
      notification.error( { message: "Failed to add master schedule event. " + errorMsg });
    }
  }

  const deleteMasterScheduleEvent = async (itemToRemoved: any) => {
    if (!itemToRemoved.id) return;
  
    try {
      await verifyCurrentEvent(itemToRemoved.startDate, itemToRemoved.endDate)
        .then(async () => {
          await configurator.api.deleteMasterScheduleEvent(itemToRemoved.id);
          notification.success({ message: "Event deleted." });
          getAllEvents();
        })
        .catch(() => {
          notification.info({ message: "Delete action canceled." });
          getAllEvents();
        });
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data?.message || e.message });
      notification.error({ message: "Failed to delete master schedule event. " + errorMsg });
    }
  };

  const onMonthChange = (month) => {
    const newValue = selectedMonth.set('month', month);
    setSelectedMonth(newValue);
  };

  const onYearChange = (year) => {
    const newValue = selectedMonth.set('year', year);
    setSelectedMonth(newValue);
  };

  const prevMonth = () => {
    setSelectedMonth(selectedMonth.subtract(1, 'month'));
  };

  const nextMonth = () => {
    setSelectedMonth(selectedMonth.add(1, 'month'));
  };

  return (
    <>
      <style>
        {`
          .ant-picker {
            width: 100% !important;
          }

          .ant-form-item {
            margin-bottom: 10px !important;
          }

          .ant-pro-card-header {
            margin-top: -8px !important;
          }

          .ant-pro-form-list-action {
            display: flex !important;
            gap: 20px;
          }

          .anticon-delete {
            color: red !important;
            transition: all 0.1s ease-in-out;
          }

          .anticon-delete:hover {
            color: #ff9999 !important;
            scale: 1.2;
          }

          .save-button,.copy-button {
            font-size: 16px; 
            margin-top: 4px;
            color: #1677FF;
            margin-left: 10px;
            transition: all 0.1s ease-in-out;
          }

          .save-button:hover,.copy-button:hover {
            color: #1890FF;
            cursor: pointer !important;
            scale: 1.2;
          }

          .ant-pro-card-title {
            color: #1677FF !important;
          }

          .hoverable {
            transition: all 0.3s ease-in-out;
          }

          .hoverable:hover {
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
            background-color: #e6f7ff;
            border-color: #91d5ff;
          }

          .hoverable .ant-pro-card-body {
            z-index: 1; // Bring content above background
          }

          .ant-form-item .ant-btn-dashed:hover {
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
            background-color: #e6f7ff;
            border-color: #91d5ff;
          }
        `}
      </style>
      {LineWithEventsAsyncState.isLoading() &&
        <Row justify={"center"}>
          <Spin/>
        </Row>
      }

      <Row justify={"end"} style={{marginTop: "10px"}}>
        <Col className="date-switch">
          <Button type="text" shape="circle" rootClassName="month-switch" onClick={prevMonth} icon={<LeftOutlined />} />

          {/* Month */}
          <Select
            value={selectedMonth.month()}
            onChange={onMonthChange}
            style={{ width: 120, margin: '0 8px' }}
            options={MONTHS.map((month, index) => {return {value: index, key: index, label: month}})}
          >
          </Select>

          {/* Year */}
          <Select
            value={selectedMonth.year()}
            onChange={onYearChange}
            style={{ width: 120, marginRight: '8px' }}
            options={years.map((year) => {return {value: year, key: year, label: year}})}
          />

          <Button type="text" shape="circle" rootClassName="month-switch" onClick={nextMonth} icon={<RightOutlined />} />
        </Col>
      </Row>

      {!LineWithEventsAsyncState.isLoading() && lineWithEvents?.sort((a, b) =>a.productionLine.localeCompare(b.productionLine))?.map((line, formIndex) => {
        const form = formInstances[formIndex];
        return (
        <div key={line.productionLine} style={{ marginBottom: "24px" }}>
          {<Divider orientation="left" orientationMargin="10px"><span style={{fontSize: "24px"}}>{Utils.snakeCaseToFirstLetterCapitalized(line.productionLine)}</span></Divider>}
          <ProForm
            layout="vertical"
            submitter={false}
            form={form}
            initialValues={{
              productionLineList: mapEventsToInitialValues(line.events, line.productionLine),
            }}
          >
            <ProFormList
              name="productionLineList"
              label=""
              creatorButtonProps={{
                creatorButtonText: "Add Event",
                style: {
                  border: "1px dashed #1677FF",
                  transition: "all 0.3s ease",
                },
              }}
              creatorRecord={{
                name: undefined,
                productionLine: line.productionLine,
                iceTaktRate: 0,
                bevTaktRate: 0,
                startDate: undefined,
                endDate: undefined,
                isDefault: false,
              }}
              min={0}
              itemRender={({ listDom, action }, { index, record }) => (
                <ProCard
                  className="hoverable"
                  bordered
                  style={{ marginBlockEnd: 8, background: (!record.id) ? "#fff9c4" : "" }}
                  title={`Event ${index + 1} ${(!record.id) ? "(Not saved)": ""}`}
                  extra={action}
                  bodyStyle={{ paddingBlockEnd: 0 }}
                >
                  {listDom}
                </ProCard>
              )}
              actionRender={(field, action, defaultActionDom, _count) => [
                <Tooltip title="Save event" key="save">
                  <SaveOutlined
                    className="save-button"
                    onClick={() => handleSave(formIndex, field.name)} // Use formIndex and item index
                  />
                </Tooltip>,
                <Tooltip title="Copy event" key="copy">
                  <CopyOutlined
                    className="copy-button"
                    onClick={() => {
                      const currentList = form.getFieldValue("productionLineList");
                      const itemToCopy = currentList[field.name];
                      const copiedItem = { ...itemToCopy, isDefault: false, name: undefined, id: undefined }; // Set isDefault to false
                      action.add(copiedItem, field.name + 1);
                    }}
                  />
                </Tooltip>,
                defaultActionDom[1], 
              ]}

              actionGuard={{
                beforeRemoveRow: async (itemIndex, _count) => {
                  const currentList = form.getFieldValue("productionLineList");
                  const index = isArray(itemIndex) ? itemIndex[0] : itemIndex;
                  const removedItem = currentList?.[index];
                  if (removedItem?.id) {
                    await deleteMasterScheduleEvent(removedItem);
                  }
                  return true;
                },
              }}
            >
              <ProForm.Item name="id" hidden></ProForm.Item>
              <Row gutter={[16, 16]}>
                <Col span={4}>
                  <ProFormText
                    name="name"
                    label="Event Name"
                    rules={[{ required: true, message: "Please input event name" }]}
                    placeholder="Enter event name"
                  />
                </Col>
                <Col span={3}>
                  <ProFormText
                    name="productionLine"
                    label="Prod. Line"
                    disabled
                  />
                </Col>
                <Col span={3}>
                  <ProForm.Item
                    name="iceTaktRate"
                    label="ICE Takt"
                    rules={[{ required: true, message: "Please input ICE takt rate" }]}
                  >
                    <InputNumber placeholder="Enter ICE takt rate" style={{width: "100%"}} step={0.5}/>
                  </ProForm.Item>
                </Col>
                <Col span={3}>
                  <ProForm.Item
                    name="bevTaktRate"
                    label="BEV Takt"
                    rules={[{ required: true, message: "Please input BEV takt rate" }]}
                  >
                    <InputNumber placeholder="Enter BEV takt rate" style={{width: "100%"}} step={0.5}/>
                  </ProForm.Item>
                </Col>
                <Col span={4}>
                  <ProFormDatePicker
                    name="startDate"
                    label="Start Date"
                    placeholder="Select start date"
                  />
                </Col>
                <Col span={4}>
                  <ProFormDatePicker
                    name="endDate"
                    label="End Date"
                    placeholder="Select end date"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, endDate) {
                          const productionLineList = getFieldValue('productionLineList');
                          const currentIndex = productionLineList.findIndex(
                            (item) => item.endDate === endDate
                          );
                          const startDate = productionLineList[currentIndex]?.startDate?.startOf('day');

                          if (!endDate || !startDate || !endDate.isBefore(startDate)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('End date must be after start date'));
                        },
                      }),
                    ]}
                  />
                </Col>
                <Col span={3}>
                  <ProFormCheckbox
                    name="isDefault"
                    label="Default Event"
                  />
                </Col>
              </Row>
            </ProFormList>
          </ProForm>
        </div>
        );
      })
    }
    </>
  );
};

export default AllProductionLineEvents;

