import {Button, Dropdown, Input, Menu, notification, Spin, Tooltip, Checkbox} from "antd";
import Sider from "antd/lib/layout/Sider";
import {Link, useHistory} from "react-router-dom";
import logo from "../../assets/images/logo.jpg";
import { LeftOutlined, CheckCircleOutlined, WarningOutlined, MoreOutlined, SettingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {CategoryIdAssembliesIdMap, QuoteAssemblyExceptionType, BaseCategory, Category, BM_BLUE} from "../../api/models";
import useCheckMobileScreen from "../../hook/useCheckMobileScreen";
import {ChangeEvent, CSSProperties, useCallback, useContext, useEffect, useState } from "react";
import { MenuItemType } from "antd/lib/menu/interface";
import _, { debounce } from "lodash";
import Utils from "../../util/util";
import {useAsyncState} from "../../hook/useAsyncState";
import { ConfiguratorContext, ModelCategoryContext, ModelCategoryContextType} from "../../context";
import {useIntl} from "react-intl";
import {QuoteAssemblyExceptionContext, QuoteAssemblyExceptionContextType} from "../../pages/configurator";
import { useQuoteContext } from "../../contexts/QuoteContext";

const AssemblySectionMenu = (props: {
  disabled?: boolean
  loading?: boolean
  computedOptions?:CategoryIdAssembliesIdMap | undefined
  filterQuery?:string | undefined
  updateFilterQuery?:(e:any)=>void
  onCategoryChange?:(c:Category|undefined)=>void
  onToggleAutoSelect?:(v:boolean)=>void
  disableAssemblyPricing?: boolean | undefined
  onToggleAssemblyPricing?:(v:boolean)=>void
  onClickCategory?:(c:number|undefined)=>void
}) => {
  const { disabled, updateFilterQuery, computedOptions } = props;

  const history = useHistory();
  const isMobile = useCheckMobileScreen();
  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const isSalesDesk = configurator.isSalesDesk();

  const [selectedMenuKey, setSelectedMenuKey] = useState<string[]>();
  const [onlyShowWarnings, setOnlyShowWarnings] = useState<boolean>(false);
  const [_isObsoleteSet, isObsoleteSetAsync] = useAsyncState<Set<number>>();

  const [_selectedCategory, selectedCategoryAsync] = useAsyncState<Category>();
  const { modelCategoriesAsync } = useContext<ModelCategoryContextType>(ModelCategoryContext);
  const categoryLst = modelCategoriesAsync?.val;

  const { quoteAsync, adminView, selectedOptions, selectedCustomOptions } = useQuoteContext();
  const quote = quoteAsync?.val;
  const quoteId = quote?.quoteId;

  const { quoteAssemblyExceptionLstAsync, loadQuoteAssemblyExceptions } = useContext<QuoteAssemblyExceptionContextType>(QuoteAssemblyExceptionContext);
  const quoteAssemblyExceptionLst = quoteAssemblyExceptionLstAsync?.val;

  useEffect(() => {
    if ( !isObsoleteSetAsync.isLoading() ) {
      loadIsObsolete();
    }
  }, [selectedOptions])

  const filterOptionsTextChanged = useCallback( debounce( (e: ChangeEvent<HTMLInputElement>) => {
    updateFilterQuery?.(e.target.value);
  }, 0), [updateFilterQuery] );

  const loadCategory = async ( categoryId?:number) : Promise<Category | undefined> => {
    if (!categoryId) return;

    try {
      selectedCategoryAsync.setLoading();
      const resp = await configurator.api.getCategory(categoryId);
      selectedCategoryAsync.setDone( resp.data );
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get category. " + errorMsg });
      selectedCategoryAsync.setFail(e.message);
    }

    return;
  };

  const loadIsObsolete = async () : Promise<number[] | undefined>  => {
    if ( !selectedOptions ) return;

    if ( quoteId && (quoteAssemblyExceptionLstAsync?.isInitial() || quoteAssemblyExceptionLstAsync?.isFail() )) {
      await loadQuoteAssemblyExceptions?.(quoteId);
    }

    const selections = Object.values(selectedOptions).flat();
    if ( !selections.length ) return;

    try {
      const resp = await configurator.api.isObsolete(selections);
      isObsoleteSetAsync.setDone(new Set(resp.data));
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get obsolete assemblies. " + errorMsg });
      isObsoleteSetAsync.setFail(e.message);
    }
  };

  const obsoleteExceptionSet = new Set( quoteAssemblyExceptionLst?.filter( a => a.type === QuoteAssemblyExceptionType.OBSOLETE )
                                       .map( a => a.assembly.id ) );

  const isObsoleteSet = new Set<number>( [...isObsoleteSetAsync.val || []].filter( n => !obsoleteExceptionSet.has( n ) ));

  const hasAssemblyExceptionSelected  = (c:BaseCategory) : boolean => {
    const exceptionIdLst = quoteAssemblyExceptionLst?.filter( ae => ae.assembly.categoryId == c.id ).map(ae => ae.assembly.id);
    return !!selectedOptions?.[ c.id ]?.some(asmId => exceptionIdLst?.includes(asmId ));
  }

  const hasCustomOption = (c:BaseCategory) : boolean => !!selectedCustomOptions?.filter( co => co.category?.id == c.id ).length;

  const updateMenuSelection = async (item: { key: string; }) => {

    setSelectedMenuKey([item.key])

    const categoryId = parseInt(item.key);

    const category = await loadCategory(categoryId);
    props.onCategoryChange?.( category );
  };

  const handleMenuSelect = async (item: { key: string; }) => {
    if (item.key === "home") return; //don't bother on back

    updateMenuSelection?.( item )
  };

  const handleClickCategory = async (item: { key: string; }) => {
    if (item.key === "home") return; //don't bother on back

    const categoryId = parseInt(item.key);
    props.onClickCategory?.( categoryId )
  };

  const hasNotApprovedCustomOption = (cat:BaseCategory) : boolean => {
    const categoryCustomOptionLst = selectedCustomOptions?.filter( co => co.category?.id == cat.id ) || [];
    return !!categoryCustomOptionLst.length && !!categoryCustomOptionLst.filter( co => !co.approved ).length
  }

  const isCategorySelectionValid = (category: BaseCategory) : boolean => {

    const selectedLst = selectedOptions?.[category.id] || [];
    const categoryCustomOptionLst = selectedCustomOptions?.filter( co => co.category?.id == category.id ) || [];

    if ( !(selectedLst.length + categoryCustomOptionLst.length) ) {
      return false;
    }

    const isObsolete = selectedLst.filter(s => isObsoleteSet?.has( s ) ).length;
    if ( isObsolete ) {
      return false;
    }

    const computedLst = computedOptions?.[category.id] || [];
    const isSeletedMatchesComputed = _.isEqual([...selectedLst].sort(), [...computedLst].sort());
    if ( !isSeletedMatchesComputed ) {
      return false
    }


    const isCustomOptionNotApproved = hasNotApprovedCustomOption(category);
    if ( isCustomOptionNotApproved ) {
      return false
    }

    const isMultipleValid = !( ( selectedLst.length + categoryCustomOptionLst.length ) > 1 && !category.allowMultiple )
    if ( !isMultipleValid ) {
      return false
    }

    return true;
  };

  const handleExitQuote = () => {
    history.push( "/quotes" );
  }

  const CategoryIcon = (props: {valid:boolean, hasCustomOption:boolean, hasAssemblyException:boolean}) => {
    const { valid, hasCustomOption, hasAssemblyException } = props;
    return ( !valid && hasCustomOption ) ? 
      <Tooltip title="Invalid custom option selected"><SettingOutlined style={{ color: 'orange', fontSize: '20px'}} /></Tooltip>
      : !valid ? <Tooltip title="Invalid selection"><WarningOutlined style={{ color: 'orange', fontSize: '20px'}} /></Tooltip>
      : hasCustomOption ? <Tooltip title="Custom option selected"><SettingOutlined style={{ color: BM_BLUE, fontSize: '20px'}} /></Tooltip>
      : hasAssemblyException ? <Tooltip title="Contains assembly exception"><InfoCircleOutlined style={{ color: BM_BLUE, fontSize: '20px'}} /></Tooltip>
      : <CheckCircleOutlined style={{ color: 'green', fontSize: '20px'}} />;
  }

  const getCategoryItem = (category:BaseCategory) : MenuItemType => {

      return {
        key: category.id,
        icon: <CategoryIcon 
          valid={isCategorySelectionValid(category)} 
          hasCustomOption={hasCustomOption(category)}
          hasAssemblyException={hasAssemblyExceptionSelected(category)}
        />,
        label: Utils.stripSortingPrefix(category.name),
      }
  }

  // It won't work if user carry both admin/engineering and sales desk role
  // Only admin engineering with adminView true can see the hidden category, sales desk also want to see it.
  const filterHidden = (c: BaseCategory): boolean => {
    return c.hidden !== true || adminView === true || isSalesDesk;
  }

  const sections = categoryLst?.filter( filterHidden )
    .reduce((sects, category) => {
      sects[category.configuratorSection] = ( sects[category.configuratorSection] || [] ).concat( category );
      return sects;
    }, {} as Record<string, BaseCategory[]> ) || {};

  const isAllCategoriesValid = () : boolean => {
    return Object.values(sections)
    .flat()
    .every(isCategorySelectionValid);
  }

  const isSectionValid = () : ( (k:string) => boolean ) => {
    if ( isAllCategoriesValid() ) return () => true;
    if ( !onlyShowWarnings ) return () => true;

    return (k:string) => !sections[k].every(isCategorySelectionValid)
  }
  const isCategoryValid = (): ( (c:BaseCategory) => boolean ) => {
    if ( isAllCategoriesValid() ) return () => true;
    if ( !onlyShowWarnings ) return () => true;

    return (c:BaseCategory) => {
      //selected is always valid
      if ( selectedMenuKey?.includes( String(c.id) ) ) return true;

      return !isCategorySelectionValid(c);
    }
  }

  const handleToggleAssemblyPricing = (checked:boolean) => {
    props.onToggleAssemblyPricing?.(checked);
  }
  const handleToggleOnlyWarnings = (checked:boolean) => {
    setOnlyShowWarnings(checked);
  }
  const handleToggleAutoSelect = (checked:boolean) => {
    props.onToggleAutoSelect?.(checked);
  }

  const getCategoryMenuItems = () : MenuItemType[] => {

    return Object.keys(sections)
    //turn off filter if all are valid or unchecked filter valid
    //else filter out sections that are all valid
    .filter(isSectionValid())
    .map( key => {

      const children = sections[key]
      //turn off filter if all are valid or unchecked filter valid
      //else filter out categories that are all valid
      .filter(isCategoryValid())
      //sort by name with sort prefix but display with sort prefix stripped
      .sort( (a,b) => a.name.toUpperCase().localeCompare( b.name.toUpperCase() ) )
      .map(getCategoryItem)

      const isValid = sections[key].every(isCategorySelectionValid) && !sections[key].some(hasNotApprovedCustomOption);

      return {
        key,
        icon: <CategoryIcon 
          valid={isValid} 
          hasCustomOption={sections[key].some(hasCustomOption)}
          hasAssemblyException={sections[key].some(hasAssemblyExceptionSelected)}
        />,
        label: key,
        children,
      }
    })
  }

  const menuStyle = {
    backgroundColor: "#001529",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#d9d9d9",
    color: "rgba( 255, 255, 255, 0.65 )",
  }

  const menuItems = getCategoryMenuItems().filter( v => v);

  const optionActionItems = new Array<MenuItemType>();

  optionActionItems.push( {
    key: "disablePricing",
    label:
    <div >
      <label>
        <Checkbox
          onChange={(e) => handleToggleAssemblyPricing(e.target.checked)}
          checked={props.disableAssemblyPricing}
        />
        <span style={{marginLeft: "0.5rem", color:menuStyle.color}}>
          Hide Assembly Pricing
        </span>
      </label>
    </div>
  } );

  optionActionItems.push( {
    key: "filterValid",
    label:
    <div >
      <label>
        <Checkbox
          onChange={(e) => handleToggleOnlyWarnings(e.target.checked)}
          checked={onlyShowWarnings}
        />
        <span style={{marginLeft: "0.5rem", color:menuStyle.color}}>
          Only Show Warnings
        </span>
      </label>
    </div>
  } );

  optionActionItems.push( {
    key: "disableAutoSelect",
    label:
    <div >
      <label>
        <Checkbox
          onChange={(e) => handleToggleAutoSelect(e.target.checked)}
        />
        <span style={{marginLeft: "0.5rem", color:menuStyle.color}}>
          Disable Auto-Select
        </span>
      </label>
    </div>
  } );

  const mobileStyle = {
    backgroundColor: "#001529"
  }
  const desktopStyle = {
    overflow: "auto",
    height: "100vh",
    position: "fixed",
    left: 0,
    zIndex: 5,
  } as CSSProperties;


  return <>
      <style>
      {`
        .menuItem {
          height: 40px;
          line-height: 40px;
          padding-inline: 24px;
          margin-inline: 4px;
          margin-block: 4px;

        }
        .menuSpin .ant-spin-nested-loading .ant-spin-container::after {
          background: transparent;
        }
      `}
      </style>
    <Sider
      style={
        isMobile ? mobileStyle : desktopStyle
      }
      width="350"
    >
      <div className="menu-logo" >
        <Link to="/">
          <img src={logo} style={{ width: 175, padding: 10 }} alt=""/>
        </Link>
      </div>
      <div className="menuItem" style={{paddingRight: 0}}>
        <div style={{display: "flex", justifyContent: "space-between" }}>
          <Button onClick={handleExitQuote}
            name="left Back"
            style={{background:"transparent", color: "rgba(255, 255, 255, 0.65)", border: "none", paddingLeft: "0px"}} >
            <LeftOutlined /> Back to main menu
          </Button>
          <Dropdown trigger={["click"]}
            placement="bottomRight"
            menu={{
              style: menuStyle,
              items:optionActionItems,
            }}
          >
            {/* this div is to avoid a warning with strict mode */}
            <div>
              <style>
                {`
                .assembly-options-btn:hover {
                  color: white !important;
                }
                `}
              </style>
              <Button 
                type="text"
                className="assembly-options-btn"
                data-testid="assembly-options-btn"
                icon={<MoreOutlined/>} 
                style={{...menuStyle, border:"none" }}
              />
            </div>
          </Dropdown>
        </div>
      </div>

      <div className="menuSpin" style={{visibility: !(disabled) ? "visible" : "hidden"}}>
        <Spin spinning={props.loading} indicator={<></>} >

        <div className="menuItem">
          <style>
            {`
              .configuratorSearchInput input {
                background-color: transparent;
                color: white !important;
              }
              .configuratorSearchInput input::placeholder {
                color: rgba( 255, 255, 255, 0.65 );
              }

              .configuratorSearchInput .ant-input-clear-icon {
                color: white;
              }
              `}
          </style>
            <div >
              <Input
                key="filter"
                className="configuratorSearchInput"
                placeholder="Filter Options"
                defaultValue={props.filterQuery}
                onChange={filterOptionsTextChanged}
                allowClear={true}
                style={{ backgroundColor: "transparent" }}
              />
        </div>
        </div>
        <Menu
          data-testid="assembly-category-menu"
          theme="dark"
          style={{ minHeight: "100vh", height: "100vh"}}
          onSelect={handleMenuSelect}
          onClick={handleClickCategory}
          selectedKeys={selectedMenuKey}
          disabled={disabled || props.loading}
          mode="inline"
          items={menuItems}
        />
      </Spin>
      </div>
    </Sider>
  </>

}

export default AssemblySectionMenu


