import React, { useContext, useState } from "react";
import { Modal, Button } from "antd";
import ModelYearSelector from "./model_year_selector";
import { listParam } from "../api";
import { ConfiguratorContext } from "../context";

export interface ModelYearModalProps {
  onYearSelected?: (year: number | undefined) => void;
}

const ModelYearModal: React.FC<ModelYearModalProps> = () => {
  const [open, setOpen] = useState(false);
  const [selectedYears, setSelectedYears] = useState<number[] | undefined>([]);
  const configurator = useContext(ConfiguratorContext);
  const [working, setWorking] = useState(false);

  const showModal = () => setOpen(true);

  const onDownload = async () => {

    if (selectedYears?.length) {

      const selectedModelYears = listParam(selectedYears);
      const downloadUrl = `${process.env.REACT_APP_API_URL}/v1/reporting/endOfYearReport?modelYear=${selectedModelYears}`;
      setWorking(true);
      try {
        await configurator.api.downloadCsv(downloadUrl, `End_Of_Year_Report_${selectedYears.sort((a, b) => a - b).join("_")}.csv`);
        setWorking(false);
      }
      catch (e) {
        console.error(e);
      }

    }
    setSelectedYears([]);
    setOpen(false);
  };

  const onCancel = () => setOpen(false);

  return (
    <>
      <Button className="report-button" onClick={showModal}>Download End of Year Report</Button>
      <Modal
        title="Select Truck Model Year"
        open={open}
        onOk={onDownload}
        onCancel={onCancel}
        okText="Download"
        okButtonProps={{ disabled: working, loading: working }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <ModelYearSelector
          value={selectedYears}
          onChange={(value) => setSelectedYears(value as number[] | undefined)}
          style={{ width: "100%" }}
          mode="multiple"
        />
      </Modal>
    </>
  );
};

export default ModelYearModal;
