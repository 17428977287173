import { Modal, ButtonProps, Button, Result, Carousel, Spin } from "antd";
import { useState, useContext, useRef } from "react";
import { ConfiguratorContext } from "../../context";
import {useIntl} from "react-intl";
import { useQuoteContext } from "../../contexts/QuoteContext";

const GenerateSerialButton = (props:ButtonProps) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>();
  const { quoteAsync } = useQuoteContext();
  const carouselRef = useRef<any>(null);
  const quote = quoteAsync?.val;

  const handleNextBtn = async () => {
    if ( !quote ) return;
    if ( !quoteAsync ) return;

    quoteAsync.setLoading();

    try {
      const resp = await configurator.api.addTrucks(quote.quoteId)
      quoteAsync.setDone( resp.data );

      carouselRef.current?.next();
    } catch (e:any) {
      quoteAsync.setFail( e.message );
      setErrorMessage(e.message);
    }
  }

  const handleCancel = () => {
    setIsOpen(false);
    carouselRef.current.goTo(0, false );
    setErrorMessage(undefined);
  }

  const errMsg = errorMessage && `An error occured: ${intl.formatMessage({ id: errorMessage })}`;

  return <>
    <Button {...props}
      onClick={() => setIsOpen(true)}
    >Generate Serial Numbers</Button>

    <Modal
      open={isOpen}
      onCancel={handleCancel}
      footer={null}
    >
      <Spin spinning={quoteAsync?.isLoading()}>
        <Carousel dots={false} ref={carouselRef} adaptiveHeight={true}>
          <div>
            <Result
              status="warning"
              subTitle={<>
                <div>This quote is not an order but serial numbers for the trucks will be generated.</div>
                <div style={{color: "red", maxHeight: "5.5rem", overflow:"auto", whiteSpace:"pre-line" }}>{errMsg}</div>
            </> }
              style={{padding: "0"}}
              extra={[
              <Button key="cancel" onClick={handleCancel}>Cancel</Button>,
              <Button key="next" type="primary" onClick={handleNextBtn} >Next</Button>
              ]}
            />
          </div>
          <div>
            <Result
              status="success"
              subTitle={`The serial numbers are ${quote?.serialNumberStr}.`}
              extra={[
                <Button type="primary" key="done" onClick={handleCancel}>Ok</Button>,
              ]}
              style={{padding: "0"}}
            />
          </div>
        </Carousel>
      </Spin>
    </Modal>
  </>
}

export default GenerateSerialButton;

