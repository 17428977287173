import Title from "antd/lib/typography/Title";
import {Result, notification, Button } from "antd";
import {useCallback, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Customer, Quote, QuoteReview, ShippingDestination} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import {useAsyncState} from "../../hook/useAsyncState";
import Utils from "../../util/util";
import BMButton, {BMButtonProps} from "../BMButton";
import QuoteReviewDetail from "../QuoteReviewDetail";
import { useQuoteContext } from "../../contexts/QuoteContext";
import ModalWizard from "../ModalWizard";
import { SelectShippingDestination } from "../ShippingDestinationModal";
import { SelectEndCustomer } from "../customer_entry";
import { WizardInstance } from "../Wizard";
import StatesSelector from "../StatesSelector";
import { debounce } from "lodash";

const SubmitQuoteButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void
  isReservation?:boolean
}) => {

  const { onChange:a, isReservation:c, ...btnProps } = props;

  const {quoteAsync, adminView, setQuoteFormValues, validateQuoteForm } = useQuoteContext();
  const quote = quoteAsync?.val;
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [review, reviewAsync] = useAsyncState<QuoteReview>();
  const [selectedStateRegistration, setSelectedStateRegistration] = useState<string>();
  const [selectedShippingDestination, setSelectedShippingDestination] = useState<ShippingDestination>();
  const [selectedEndCustomer, setSelectedEndCustomer] = useState<Customer>();
  const [initialValue, setInitialValue] = useState<Quote>();
  const [isQuoteValid, setIsQuoteValid] = useState<boolean>();

  useEffect(() => {
    if ( isOpen ) { 
      validateQuoteForm?.()
        .then( validForm => {
          setIsQuoteValid( !!validForm )
        });
    }
  }, [quote, isOpen])

  const handleOpen = (open:boolean) => {

    if(open) {
      setInitialValue( quote && {...quote});
      setSelectedStateRegistration(quote?.stateRegistration);
      setSelectedShippingDestination(quote?.shippingDestination);
      setSelectedEndCustomer(quote?.endCustomer);
    }
  };

  const submit = async () : Promise<Quote|undefined> => {
    if (!quote) return;

    quoteAsync.setLoading();
    try {
      const options = {
        reservation: props.isReservation
      };
      const resp = await configurator.api.submitQuoteApproval(quote.displayRevisionId, options );
      quoteAsync.setDone(resp.data);

      notification["success"]({
        message: "Submitted",
        description: "Your quote has been submitted for approval",
      });

      setIsOpen(false);
      return resp.data;
    } catch (e: any) {

      const errorMsg = intl.formatMessage({ id: e.message });
      const msg = "Failed to submit quote. " + errorMsg
      notification.error( { message: msg, duration: 0 });
    }

    return;
  };

  const loadReview = async (quoteRevisionId:number | undefined) : Promise<QuoteReview | undefined> => {
    if ( !quoteRevisionId ) return;

    reviewAsync.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteReview(quoteRevisionId, { dealerView:!adminView });
      reviewAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
      reviewAsync.setFail(e.message);
    }
    return;
  }

  const handleOpenBtn = async () => {

    const isValid = await validateQuoteForm?.();

    const review = ( reviewAsync.isInitial() ) ? await loadReview(quote?.displayRevisionId) : reviewAsync.val;
    const needsReview = !isValid || props.isReservation || Utils.reviewHasErrors(review) ;
    if ( needsReview ) {
      setIsOpen( true );
    }
    else {
      handleSubmit();
    }
  }

  const isLastStep = (nav:WizardInstance) => {
    return nav.getLastStep()?.key == nav.getCurrentStep()?.key;
  }

  const nextStep = (nav:WizardInstance) => {

    if ( isLastStep(nav) ) {
      handleConfirmSubmitBtn(nav);
    }
    else {
      nav.nextStep();
    }
  }

  const handleSelectCustomer = async (nav:WizardInstance) => {
    if (!quote) return;

    await setQuoteFormValues?.({
      ...quote,
      endCustomer:quote.endCustomer || selectedEndCustomer,
    });

    nextStep(nav);
  }

  const handleSelectDestination =  async (nav:WizardInstance) => {
    if (!quote) return;

    await setQuoteFormValues?.({
      ...quote,
      shippingDestination: quote.shippingDestination || selectedShippingDestination,
    });

    nextStep(nav);
  }

  const handleSelectStateRegistration = async (nav:WizardInstance) => {
    if (!quote) return;

    await setQuoteFormValues?.({
      ...quote,
      stateRegistration: quote.stateRegistration || selectedStateRegistration
    });

    nextStep(nav);
  }

  const handleConfirmSubmitBtn = async (nav:WizardInstance) => {
    handleSubmit();
  }

  const handleSubmit = useCallback(
    debounce(async () => {
      const quote = await submit();
      if (quote) {
        props.onChange?.(quote);
        setIsOpen(false);
      }
    }, 2000, {leading: true, trailing: false}),
    []
  );

  useEffect(() => {
    return () => handleSubmit.cancel();
  }, [handleSubmit]);

  const handleCancel = () => {
    setIsOpen(false);
  }

  const isWorking = quoteAsync?.isLoading() || reviewAsync.isLoading();

  const btnTitle = props.isReservation ? "Submit as Reservation" : "Submit Quote";

  return <>
    <BMButton
      type="primary"
      onClick={handleOpenBtn}
      {...btnProps}
      loading={isWorking}
    > {btnTitle}</BMButton>
    <ModalWizard
      centered
      open={isOpen}
      onCancel={handleCancel}
      width={'50rem'}
      showSteps={false}
      afterOpenChange={handleOpen}
      steps={[
        {
          key:"reviewQuote",
          hidden: !Utils.reviewHasErrors(review),
          body:(_nav) => <Result status="warning" 
            title={"The following issues with the quote have been identified."}
            subTitle={<Title level={5}>Continue to submit for approval?</Title> }
          >
            <QuoteReviewDetail 
              review={review} 
              ignoreDashDrawing={true} 
            />
          </Result>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" loading={isWorking} onClick={() => nextStep(nav)} >{isLastStep(nav) ? "Continue" : "Next"}</Button>
            <Button key="back" onClick={handleCancel} >Cancel</Button>
          </div>,
        },
        {
          key:"stateRegistration",
          hidden: !!initialValue?.stateRegistration,
          body:(_nav) => <div>
            <div style={{display:"flex", flexDirection: "column", marginTop: "2rem", alignItems: "center", gap: "1rem"}} >
            <Title level={5}>Please select the state to be registered.</Title>
            <StatesSelector includeTbd={true} allowClear={false} value={selectedStateRegistration} onChange={setSelectedStateRegistration} style={{width: "10rem"}} />
            </div>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" disabled={!selectedStateRegistration} loading={isWorking} onClick={() => handleSelectStateRegistration(nav)} >{isLastStep(nav) ? "Continue" : "Next"}</Button>
            <Button key="cancel" type="primary" onClick={handleCancel} >Cancel</Button>
          </div>,
        },
        {
          key:"selectShippingDestination",
          hidden: !!initialValue?.shippingDestination,
          body:(_nav) => <div>
            <Title level={5}>A shipping destination is required.</Title>
            <SelectShippingDestination value={selectedShippingDestination} onChange={setSelectedShippingDestination} />
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" disabled={!selectedShippingDestination} loading={isWorking} onClick={() => handleSelectDestination(nav)} >{isLastStep(nav) ? "Continue" : "Next"}</Button>
            <Button key="cancel" type="primary" onClick={handleCancel} >Cancel</Button>
          </div>,
        },
        {
          key:"selectCustomer",
          hidden: !!initialValue?.endCustomer,
          body:(_nav) => <div>
            <Title level={5}>A customer is required.</Title>
            <SelectEndCustomer value={selectedEndCustomer} onChange={setSelectedEndCustomer} />
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" disabled={!selectedEndCustomer} loading={isWorking} onClick={() => handleSelectCustomer(nav)} >{isLastStep(nav) ? "Continue" : "Next"}</Button>
            <Button key="cancel" type="primary" onClick={handleCancel} >Cancel</Button>
          </div>,
        },
        {
          key:"setReservation",
          hidden: !props.isReservation,
          body:(_nav) => <Result status="warning" 
            title={"This is a reservation."}
            subTitle={<Title level={5}>Continue to submit for approval?</Title> }
          >
            <ul>
              <li>This is a reservation for future production capacity.</li>
              <li>It requires approval before it will take effect.</li>
              <li>It must be converted into a quote to become an order.</li>
            </ul>
          </Result>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={() => handleConfirmSubmitBtn(nav)} loading={isWorking} >Continue</Button>
            <Button key="back" onClick={handleCancel} >Cancel</Button>
          </div>,
        }
      ]}
    />
  </>
}

export default SubmitQuoteButton;
