import { Button, Checkbox, Col, DatePicker, Form, Input, Modal, notification, Row, Segmented, Select, Space, Spin, Tooltip } from "antd";
import Title from "antd/es/typography/Title";
import dayjs, { Dayjs } from "dayjs";
import { InputDateTimeFormat, MasterScheduleEvent, ProductionLine, ProductionLineWithEvents, ScheduledTruck, ShortTimeFormat } from "../../api/models";
import { useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext } from "../../context";
import { useIntl } from "react-intl";
import Utils from "../../util/util";
import { FormInstance, useWatch } from "antd/es/form/Form";
import { CalendarOutlined, CarryOutOutlined } from "@ant-design/icons";
import { useAsyncState } from "../../hook/useAsyncState";
import Carousel, { CarouselRef } from "antd/lib/carousel";
import BMButton from "../../components/BMButton";
import AllProductionLineEvents from "./master_schedule_slots_event_list";
import MonthCalendar from "./master_schedule_event_calendar";
import { NumberParam, useQueryParam } from "use-query-params";

export const ALL = "All";
export const ALL_LINES = [ProductionLine.LINE_1, ProductionLine.LINE_2, ProductionLine.LINE_3];

const MasterScheduleSlots = () => {

  const [segmentParam, setSegmentParam] = useQueryParam<number | null | undefined>("segment", NumberParam);

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [productionLines, setProductionLines] = useState<string[]>(ALL_LINES);
  const [lineWithEvents, LineWithEventsAsyncState] = useAsyncState<ProductionLineWithEvents[]>();
  const [selectedMonth, setSelectedMonth] = useState<Dayjs>(dayjs());
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(!!segmentParam ? undefined : dayjs());
  const selectedEventId = useWatch('id', form);
  const isDefault = useWatch('isDefault', form);
  const selectedLineOnEvent = useWatch('productionLine', form);
  const [calendarView, setCalendarView] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [showDefaultEvent, setShowDefaultEvent] = useState(false);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string[]>(ALL_LINES);


  const carouselRef = useRef<CarouselRef | null>(null);

  useEffect(() => {
    getMasterScheduleSlots();
  }, [productionLines, selectedMonth]);

  useEffect(() => {
    if (!!segmentParam) {
      setCalendarView(segmentParam);
    }
  }, []);


  const getInputDate = () => {
    const startDate = selectedMonth.startOf('month').subtract(30, 'day').format(InputDateTimeFormat);
    const endDate = selectedMonth.endOf('month').add(30, 'day').format(InputDateTimeFormat);
    return [startDate, endDate];
  }

  const getInputLines = () => {
    return productionLines.includes(ALL) ? [ProductionLine.LINE_1, ProductionLine.LINE_2, ProductionLine.LINE_3] : productionLines;
  }


  const getMasterScheduleSlots = async () => {

    if (!productionLines.length) return;

    LineWithEventsAsyncState.setLoading();
    const [startDate, endDate] = getInputDate();
    const requestedLines = getInputLines();

    try {
      const resp = await configurator.api.getMasterScheduleEvents(requestedLines, startDate, endDate);
      LineWithEventsAsyncState.setDone(resp.data);
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data?.message || e.message });
      notification.error( { message: "Failed to load master schedule slots. " + errorMsg });
    }
  }

  const getFormDate = () => {

    const startDate = form.getFieldValue("startDate").format(InputDateTimeFormat);
    const endDate = form.getFieldValue("endDate")?.format(InputDateTimeFormat) || form.getFieldValue("startDate").add(1, 'day').format(InputDateTimeFormat);

    return {
      iceTaktRate: Number(form.getFieldValue("iceTaktRate")),
      bevTaktRate: Number(form.getFieldValue("bevTaktRate")),
      startDate: startDate,
      endDate: isDefault ? form.getFieldValue("endDate") : endDate,
    }
  }

  const createMasterScheduleEvent = async () => {
    if (!productionLines) return;
    try {

      await configurator.api.createMasterScheduleEvent({
        ...form.getFieldsValue(),
        ...getFormDate(),
      });

      notification.success( { message: "Event created. " });

      getMasterScheduleSlots();
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data?.message || e.message });
      notification.error( { message: "Failed to create master schedule slot. " + errorMsg });
    }
  }

  const updateMasterScheduleEvent = async () => {
    if (!productionLines || !selectedEventId) return;
    try {

      await configurator.api.updateMasterScheduleEvent({
        ...form.getFieldsValue(),
        ...getFormDate(),
      });

      notification.success( { message: "Event updated. " });

      getMasterScheduleSlots();
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data?.message || e.message });
      notification.error( { message: "Failed to update master schedule slot. " + errorMsg });
    }
  }


  const deleteMasterScheduleEvent = async () => {
    const eventId = form.getFieldsValue().id;

    if (!eventId) return;
    try {
      await configurator.api.deleteMasterScheduleEvent(eventId);
      notification.success( { message: "Event deleted. " });
      setOpen(false);
      getMasterScheduleSlots();
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data?.message || e.message });
      notification.error( { message: "Failed to delete master schedule slot. " + errorMsg });
    }
  }

  const isDateInRange = (value: Dayjs | undefined, startDate: Date | undefined, endDate?: Date | undefined): boolean => {

    if (!value) return false;

    value = value.utc();

    const start = dayjs(startDate).utc();
    const end = endDate ? dayjs(endDate).utc() : null;
  
    return end
      ? (value.isAfter(start, "day") && value.isBefore(end, "day")) ||
        value.isSame(start, "day") ||
        value.isSame(end, "day") // Check if value is within startDate and endDate
      : value.isAfter(start, "day") || value.isSame(start, "day"); // For open-ended ranges, check if value is after or same as startDate
  };

  const getListData = (value: Dayjs, productionLine: string): MasterScheduleEvent[] => {
    const eventList = lineWithEvents?.find(le => le.productionLine === productionLine)?.events.filter((event) => {
      return isDateInRange(value, event.startDate, event.endDate);
    });

    return eventList?.map((event) => ({
      ...event,
    })) || [];
  };

  const onAddEvent = (addEventFromDate = false) => {
    const selectedLine = form.getFieldValue("productionLine"); // keep the selectedLine if it exists.
    form.resetFields();
  
    if (addEventFromDate) {
      form.setFieldsValue({
        startDate: selectedDate,
        productionLine: selectedLine,
      });
  
      if (carouselRef.current) {
        carouselRef.current.goTo(1);
      }
    } else {
      if (carouselRef.current) {
        carouselRef.current.goTo(0);
      }
    }
  
    setOpen(true); // Open the modal
  };

  const switchLines = (eventId: number) => {

    const selectedEvent = lineWithEvents?.filter(le => le?.productionLine === selectedLineOnEvent)[0]?.events
      .find(event => event.id === eventId);

    if (selectedEvent) {
      form.setFieldsValue({
        name: selectedEvent.name,
        productionLine: selectedEvent.productionLine,
        bevTaktRate: selectedEvent.bevTaktRate,
        iceTaktRate: selectedEvent.iceTaktRate,
        startDate: selectedEvent.startDate ? dayjs(selectedEvent.startDate) : undefined,
        endDate: selectedEvent.endDate ? dayjs(selectedEvent.endDate) : undefined,
        isDefault: selectedEvent.isDefault,
        id: selectedEvent.id,
      });
    }
  }

  const handleProductionLineChange = (value: string[]) => {
    if (selectedOption.includes(ALL)) {
      const newSelection = value.filter(option => option !== ALL);
      setProductionLines(newSelection);
      setSelectedOption(newSelection); // Show only the newly selected line(s)
    } else {
      const newSelection = value.includes(ALL) ? [ALL] : value;
      setProductionLines(newSelection);
      setSelectedOption(newSelection);
    }
  };

  const handleProductionLineCancel = () => {
    setOpen(false);
    setSelectedDate(undefined);
    form.resetFields();

    if (carouselRef.current) {
      carouselRef.current.goTo(0);
    }
  };

  const handleProductionLineOk = () => {
    form.validateFields().then(values => {
      !!selectedEventId ? updateMasterScheduleEvent() : createMasterScheduleEvent();
      setOpen(false);
      form.resetFields();
    });
  };

  const onSelectLineTitle = (productionLine: string, eventId: number, currentDate: Dayjs) => {

    setSelectedDate(currentDate);

    const selectedEvent = lineWithEvents?.filter(le => le.productionLine === productionLine)[0].events
      .find(event => event.id === eventId);

    if (selectedEvent) {
      form.setFieldsValue({
        name: selectedEvent.name,
        productionLine: selectedEvent.productionLine,
        bevTaktRate: selectedEvent.bevTaktRate,
        iceTaktRate: selectedEvent.iceTaktRate,
        startDate: dayjs(selectedEvent.startDate),
        endDate: selectedEvent.endDate ? dayjs(selectedEvent.endDate) : undefined,
        isDefault: selectedEvent.isDefault,
        id: selectedEvent.id,
      });

      setOpen(true);
    }
  }

  const onDayChange = (value: Dayjs) => {
    if (value.month() !== selectedMonth.month()){
      setSelectedMonth(value);
    }
  }

  const getTruckOnDay = (value: Dayjs, productionLine: string): ScheduledTruck[] | undefined => {
    const day = value.format(ShortTimeFormat);
    return lineWithEvents?.filter(le => le.productionLine === productionLine)
        .reduce((acc: ScheduledTruck[], le) => {
            const trucks = le.scheduledTrucks[day];
            if (!!trucks) {
                return acc.concat(trucks);
            }
            return acc;
        }, []);
  };

  const existAssignedEvent = () => {
    const selectedLine = form.getFieldValue("productionLine");
    return lineWithEvents
                ?.find((le) => le.productionLine === selectedLine)
                ?.events
                .some(event => isDateInRange(selectedDate, event.startDate, event.endDate) && !event.isDefault);
  }

  const isButtonDisabled = () => {
    if (!!selectedEventId) {
      return false;
    }
    
    const assignedEventExists = existAssignedEvent();
    return !(assignedEventExists && isDefault) && assignedEventExists;
  };

  const onSwitchSegment = () => {
    setCalendarView(calendarView ^ 1); 
    setSegmentParam(calendarView ^ 1);
    setSelectedDate(undefined);
  }

  return (
  <>
    <style>
      {`
        .ant-segmented-item-selected {
          background: #1677FF !important;
          color: white !important;
        }
          
        .line-selector .ant-select-selection-item {
          color: white !important;
          background: #1677FF !important;
          border-radius: 9px !important;
        }

        .line-selector .ant-select-selection-item-remove {
          color: white !important;
        }

        .line-selector .ant-select-selector {
          border-radius: 15px !important;
        }

        .ant-segmented {
          border-radius: 15px !important;

          .ant-segmented-item {
            border-radius: 15px !important;
          }

          .ant-segmented-item:hover {
            border-radius: 15px !important;
          }
        }
        .ant-checkbox {
          margin-top: -1px !important;
          .ant-checkbox-inner {
            width: 25px !important;
            height: 25px !important;
            border-radius: 8px !important;
            margin-bottom: 1px !important;
          }

          .ant-checkbox-inner::after {
            transform: rotate(45deg) scale(2) translate(-30%, -40%) !important;
          }
        }

        .ant-checkbox-wrapper {
          span {
            font-size: 18px !important;
            margin-bottom: 3px
          }
        }

        .ant-picker.event-date-picker {
          width: 300px !important;
          border-radius: 15px !important;
        }
      `}
    </style>
    <div className="site-layout-background">
      <Space direction="vertical" style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: "space-between", gap: '5px' }}>
          <Title level={2}>Master Schedule Slot</Title>
        </div>

        <Row justify={"space-between"}>

          <Col>
          <Row>
            <Segmented
              options={[ 
                {
                  value: 0, 
                  icon:  <Tooltip title="Calendar">
                          <CalendarOutlined />
                        </Tooltip>
                }, 
                {
                  value: 1,
                  icon: <Tooltip title="Events">
                          <CarryOutOutlined />
                        </Tooltip>
                }
              ]}
              onChange={onSwitchSegment}
              value={calendarView}
              style={{marginRight: "20px"}}
            />
            <Select
              placeholder="Select production line"
              className="line-selector"
              style={{ width: 240 }}
              mode="multiple"
              onChange={handleProductionLineChange}
              value={selectedOption}
              options={Object.values(ProductionLine).map(l => String(l)).concat(ALL)?.map((line) => (
                {key: line, value: line,
                label: Utils.snakeCaseToFirstLetterCapitalized(line)}
              ))}
            />
            {calendarView !== 0 && <Checkbox style={{marginLeft: "20px"}} onChange={(event) => setShowDefaultEvent(event.target.checked)}>Default Event</Checkbox>}
            </Row>
          </Col>

          <Col>
            {(calendarView == 0) ? 
            <Button type="primary" onClick={() => onAddEvent(false)} style={{marginRight: "10px"}}>Add Event</Button>
            : 
            <>
              <Button type="primary" style={{marginRight: "10px"}} onClick={() => {setShowAllEvents((prev) => !prev)}}>{showAllEvents ? "Month Event" : "All Events" }</Button>
              <Button type="primary" onClick={() => setRefresh(!refresh)} style={{marginRight: "10px"}}>Refresh</Button>
            </>
            }
          </Col>
        </Row>
        <div>
          { 
            calendarView == 0 ?
            (lineWithEvents == undefined ? 
            <Row justify={"center"}>
              <Spin/>
            </Row>
            :
            <MonthCalendar 
              selectedProductionLines={productionLines}
              loading={LineWithEventsAsyncState.isLoading()}
              getListData={getListData}
              onSelectLineTitle={onSelectLineTitle}
              onDayChange={onDayChange}
              getTruckOnDay={getTruckOnDay}
              selectedMonth={selectedMonth}
            />)
            :
            <AllProductionLineEvents
              productionLines={productionLines}
              refresh={refresh}
              setRefresh={setRefresh}
              showDefaultEvent={showDefaultEvent}
              showAllEvents={showAllEvents}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
            />
          }
        </div>
      </Space>
    </div>

    <Modal
      title={`${selectedEventId ? "Edit" : "Add"} Event for Takt Rate`}
      open={open}
      onOk={handleProductionLineOk}
      onCancel={handleProductionLineCancel}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button key="delete" type="primary" danger onClick={deleteMasterScheduleEvent}>
            Delete Event
          </Button>
          <Row gutter={12}>
            <Col>
          {selectedEventId && (
            <Button key="reset" type="default" onClick={() => onAddEvent(true)}>
              Add New Event
            </Button>
          )}
          </Col>
          <Col>
            <BMButton type="primary"
              disabled={isButtonDisabled()}
              onDisabledClick={() => notification.warning({message: "There is existing event to on this day, please remove the existing event before create new one" })}
              onClick={handleProductionLineOk}
            >
              <span>{selectedEventId ? "Save" : "Add"}</span>
            </BMButton>
          </Col>
          </Row>
        </div>
      }
    >

    <style>
      {`
        .carousel-slide {
          min-height: 550px; /* Higher than content to make transition work */
        }
      `}
    </style>
    <Carousel ref={carouselRef} dots={false}>
      <CarouselSlide
        selectedEventId={selectedEventId}
        lineWithEvents={lineWithEvents}
        selectedLineOnEvent={selectedLineOnEvent}
        switchLines={switchLines}
        form={form}
        selectedDate={selectedDate}
        isDateInRange={isDateInRange}
      />
      <CarouselSlide
        selectedEventId={undefined}
        lineWithEvents={lineWithEvents}
        selectedLineOnEvent={selectedLineOnEvent}
        switchLines={switchLines}
        form={form}
        selectedDate={selectedDate}
        isDateInRange={isDateInRange}
      />
    </Carousel>
  </Modal>

  </>
  );
}

export default MasterScheduleSlots;


interface CarouselSlideProps {
  selectedEventId: number | undefined;
  lineWithEvents: ProductionLineWithEvents[] | undefined;
  selectedLineOnEvent: ProductionLine;
  switchLines: (lineId: number) => void;
  form: FormInstance;
  selectedDate: Dayjs | undefined;
  isDateInRange: (value: Dayjs | undefined, startDate: Date | undefined, endDate?: Date | undefined) => boolean;
}

const CarouselSlide: React.FC<CarouselSlideProps> = (props: CarouselSlideProps) => {

  const {selectedEventId, lineWithEvents, selectedLineOnEvent, switchLines, form, selectedDate, isDateInRange} = props;

  const eventOptions = lineWithEvents
    ?.find((le: ProductionLineWithEvents) => le.productionLine === selectedLineOnEvent)
    ?.events.filter((event: MasterScheduleEvent) => isDateInRange(selectedDate, event.startDate, event.endDate))
    .map((e: MasterScheduleEvent) => ({
      key: e.id,
      value: e.id,
      label: Utils.snakeCaseToFirstLetterCapitalized(e.name),
    }));

  return (
    <div className="carousel-slide">
      {selectedEventId && (
        <Select
          placeholder="Select Event"
          style={{ width: 350, marginBottom: "40px" }}
          value={lineWithEvents
            ?.find((le) => le.productionLine === selectedLineOnEvent)
            ?.events.find((event) => event.id === selectedEventId)?.id}
          options={eventOptions}
          onChange={switchLines}
        />
      )}
      <Form form={form} layout="vertical">
        <SharedForm
          productionLines={Object.values(ProductionLine)}
          selectedLineOnEvent={selectedLineOnEvent}
        />
      </Form>
    </div>
  );
};

const SharedForm: React.FC<{ productionLines: string[], selectedLineOnEvent?: string }> = ({ productionLines, selectedLineOnEvent }) => {
  return (
    <><Form.Item name="id" hidden={true} /><Form.Item
      label="Name"
      name="name"
      rules={[{ required: true, message: "Please input name" }]}
    >
      <Input placeholder="Enter event name" />
    </Form.Item><Form.Item
      label="Production Line"
      name="productionLine"
      rules={[{ required: true, message: "Please select production line" }]}
    >
        <Select
          placeholder="Select production line"
          value={selectedLineOnEvent}
          options={productionLines.map((line) => ({
            key: line,
            value: line,
            label: Utils.snakeCaseToFirstLetterCapitalized(line.split("_").slice(1).join(" ")),
          }))}
          allowClear />
      </Form.Item><Row justify={"space-between"}>
        <Form.Item
          label="ICE Takt Rate"
          name="iceTaktRate"
          rules={[{ required: true, message: "Please input takt rate" }]}
        >
          <Input
            type="number"
            step="0.5"
            placeholder="Enter takt rate"
            style={{ width: "200px" }} />
        </Form.Item>
        <Form.Item
          label="BEV Takt Rate"
          name="bevTaktRate"
          rules={[{ required: true, message: "Please input takt rate" }]}
        >
          <Input
            type="number"
            step="0.5"
            placeholder="Enter takt rate"
            style={{ width: "200px" }} />
        </Form.Item>
      </Row><Row justify={"space-between"}>
        <Form.Item name="startDate" label="Start Date">
          <DatePicker style={{ width: "200px" }} />
        </Form.Item>
        <Form.Item name="endDate" label="End Date">
          <DatePicker style={{ width: "200px" }} />
        </Form.Item>
      </Row><Form.Item name="isDefault" label="Default" valuePropName="checked" initialValue={false}>
        <Checkbox />
      </Form.Item></>
  );
};


