import { useState } from "react"
import {Descriptions, DescriptionsProps, Pagination } from "antd";

interface DescriptionPagination {
  pageSize: number
  current: number
  total: number
}

const PagedDescriptions = (props: Omit<DescriptionsProps, 'column'> & {
  column: number
  rows: number
}) => {
  const {rows, ...descriptionProps} = props;
  const {items, column} = descriptionProps;
  const pageSize = props.rows * column;
  const [pagination, setPagination] = useState<DescriptionPagination>({
    pageSize,
    current: 1,
    total: items?.length || 0
  });
  const paginatedData = items?.slice(
    (pagination.current - 1) * pageSize,
    pagination.current * pageSize
  );

  return <>
    <Descriptions
      style={{maxHeight: "10rem", overflow: "auto"}}
      {...descriptionProps}
      items={paginatedData} 
    />
    {((items?.length || 0) > pageSize) &&
      <div style={{display:"flex", flexDirection: "row-reverse" }}>
        <Pagination {...pagination}
          onChange={(page) => setPagination({...pagination, current:page})}
        />
      </div>}
  </>
}

export default PagedDescriptions;

