import Quotes from "./quotes";

export const DEFAULT_SOURCEWELL_FILTER = {
  myQuotes: false,
  incentivePrograms: ['Sourcewell 2022','Sourcewell 2024'],
};
const SourcewellQuotes = () => <Quotes 
  title="Sourcewell"
  defaultFilter={DEFAULT_SOURCEWELL_FILTER}
/>;


export default SourcewellQuotes;

