import { useCallback, useContext, useEffect, useRef, useState, useMemo } from "react";
import { ConfiguratorContext } from "../../context";
import { Button, Calendar, Checkbox, DatePicker, notification, Segmented, Select, Skeleton, Space, Table, TableColumnType, TablePaginationConfig, Tooltip } from "antd";
import { AXIOS_CANCEL_MSG, PAGINATION_MAX_PAGE_SIZE, Permission, ScheduledTruckDto, SortDirection, TruckDateFilterType, TruckFilter } from "../../api/models";
import Title from "antd/es/typography/Title";
import dayjs, { Dayjs } from "dayjs";
import TruckFilterControls from "../../components/TruckFilterControls";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CalendarProps } from "antd/lib";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import { AsyncState, useAsyncState } from "../../hook/useAsyncState";
import { useIntl } from "react-intl";
import axios, {CancelTokenSource} from "axios";
import { getCSVRow } from "../../helpers/csv";
import { CalendarOutlined, UnorderedListOutlined  } from "@ant-design/icons";
import { ArrayParam, BooleanParam, NumericArrayParam, StringParam, useQueryParam } from "use-query-params";
import Utils from "../../util/util";
import weekday from 'dayjs/plugin/weekday';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(utc);
dayjs.extend(timezone);

const plantTz = 'America/New_York';

dayjs.extend(weekday);
dayjs.extend(updateLocale);
// Update the locale to for week day name
dayjs.updateLocale('en', {
  weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'], // Customize this array as needed
});

enum CalendarViewDate {
    PRODUCTION, SHIPPING
}

function getDateFilterStartEndAround(date: Dayjs) {
  return [
    date.tz(plantTz, true).startOf('month').subtract(6, 'day'),
    date.tz(plantTz, true).endOf('month').add(6, 'day'),
  ];
}

const DEFAULT_PAGE_SORT = {
  field:  'projectedProductionDate',
  direction: 'asc' as SortDirection,
}

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_FILTER:TruckFilter = {
  dealerLst: [],
  dateFilterType: TruckDateFilterType.PRODUCTION,
  dateFilterStart: dayjs(new Date()).tz(plantTz, true).startOf('month'),
  dateFilterEnd: dayjs(new Date()).add(10, 'year').tz(plantTz, true)
};

export interface TruckCalendarDto {
  id: number;
  truckSerialNumberStr: string;
  productionDate?: Date | null | undefined;
  shipDate?: Date | null | undefined;
  endCustomer: string;
  quoteId: string;
}

const ScheduleCalendarView = () => {

    const configurator = useContext(ConfiguratorContext);
    const [calendarTrucks, calendarTrucksAsync] = useAsyncState<TruckCalendarDto[]>();
    const cancelTokenCalendarRef = useRef<CancelTokenSource>();
    const intl = useIntl();

    const [filter, setFilter] = useState<TruckFilter>( {
      ...DEFAULT_FILTER,
    });

    const [value, setValue] = useState<Dayjs>();
    const [calendarViewDate, setCalendarViewDate] = useState(CalendarViewDate.PRODUCTION);

    const listData = useMemo( () => (calendarTrucks?.reduce( (acc,t) => {

      const prodDate = dayjs(t.productionDate).tz('utc').tz(plantTz, true).startOf('day');
      const shipDate = dayjs(t.shipDate).tz('utc').tz(plantTz, true).startOf('day');
      const displayDate = calendarViewDate == CalendarViewDate.PRODUCTION ? prodDate : shipDate;

      const dateNdx = displayDate.format("MM/DD/YYYY"); 
      acc[ dateNdx ] = (acc[ dateNdx ] || []).concat(t);
      return acc;

    }, {} as Record<string, TruckCalendarDto[]>) ), [calendarTrucks, calendarViewDate]);

    const onPanelChange = (value: Dayjs, _mode: CalendarProps<Dayjs>['mode']) => {
      const [dateFilterStart, dateFilterEnd] = getDateFilterStartEndAround(value);
      setFilter({ ...filter, dateFilterStart, dateFilterEnd })
      setValue(value);
    };

    useEffect(() => {
      loadCalendarDto(filter);
    }, [filter]);

    const loadCalendarDto = async (filter:TruckFilter) => {
    
      calendarTrucksAsync?.setLoading()
      try {
        const resp = await Utils.executeWithCancelToken(cancelTokenCalendarRef, (token) =>
          configurator.api.listMasterScheduleCalendar({ ...filter, page: 0, size: PAGINATION_MAX_PAGE_SIZE, sort:DEFAULT_PAGE_SORT }, token)
        );
        calendarTrucksAsync?.setDone(resp?.data?.content);
      }
      catch (e: any) {
        const errorMsg = intl.formatMessage({ id: e.message });
        notification.error( { message: "Failed load trucks. " + errorMsg });
        calendarTrucksAsync.setFail(e.message);
      }
    }

    const dateCellRender = (value: Dayjs) => {

      const lst = listData?.[ value.format("MM/DD/YYYY" ) ];
      return (
        /* TODO - in future add some styling to reflect how full a slot is */
        /*<div style={{ backgroundColor: listData.length > 2 ? 'rgba(255, 0, 0, 0.3)' : '' }}>*/

        <div className="custom-calendar-cell">

          <ul style={{ listStyleType: 'none', padding: 0, fontSize: "10px" }}>
            {lst?.map((item) => (
              <li key={item.id}>
                <Link to={'/configurator/' + item.quoteId} target="_new">
                  {item.truckSerialNumberStr} ({item.endCustomer})
                </Link>
              </li>
            ))}
          </ul>
        </div>
      );
    };

    const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
        if (info.type === 'date') return dateCellRender(current);
        return info.originNode;
    };

    return (
      <>
        <style>
          {`
            .custom-calendar-cell {
                  overflow-y: auto;
                  max-height: 100px;
                  padding-right: 12px;
                  width: 100%;
              }

              .custom-calendar-cell::-webkit-scrollbar {
                  width: 8px;
              }

              .custom-calendar-cell::-webkit-scrollbar-track {
                  background: #e1e9eb;
              }

              .custom-calendar-cell::-webkit-scrollbar-thumb {
                  background-color: #c9cecf;
                  border-radius: 4px;
                  border: 1px solid #c9cecf;
              }

              .custom-calendar-cell::-webkit-scrollbar-thumb:hover {
                  background-color: darkgrey;
              }

              /* Hide overflow in the calendar cells */
              .ant-picker-calendar-date-content {
                  overflow: hidden !important;
              }
                      
          `}
        </style>
        <Skeleton active loading={calendarTrucksAsync.isLoading() || !calendarTrucksAsync?.val} style={{marginTop: "1rem"}}>
          <Select style={{ width: '300px' }} placeholder="Select date to view" value={calendarViewDate} onChange={setCalendarViewDate}>
              <Select.Option key={CalendarViewDate.PRODUCTION} value={CalendarViewDate.PRODUCTION}>Production Date</Select.Option>
              <Select.Option key={CalendarViewDate.SHIPPING} value={CalendarViewDate.SHIPPING}>Ship Date</Select.Option>
          </Select>
          <style>
            {`
              .ms-calendar .ant-picker-calendar-mode-switch {
                display:none;
              }

              .ms-calendar .ant-picker-content th:nth-child(1), /* Sunday header */
              .ms-calendar .ant-picker-content th:nth-child(7), /* Saturday header */
              .ms-calendar .ant-picker-cell:nth-child(7n+1), /* Sunday cells */
              .ms-calendar .ant-picker-cell:nth-child(7n) { /* Saturday cells */
              }
              
            `}
          </style>
          <Calendar style={{marginTop: "-2rem"}} className="ms-calendar" value={value} cellRender={cellRender} onPanelChange={onPanelChange} />
        </Skeleton>
      </>
    );
};

const MasterScheduleView = () => {
    const intl = useIntl();
    const configuratorContext = useContext(ConfiguratorContext);

    const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("filter", StringParam);
    const [dealerFilterParam, setDealerFilterParam] = useQueryParam<Array<string | null> | undefined | null>("dealer", ArrayParam);
    const [salespersonsParam, setSalespersonsParam] = useQueryParam<Array<string | null> | undefined | null>("salespersons", ArrayParam);
    const [endCustomerParam, setEndCustomerParam] = useQueryParam<Array<number | null> | undefined | null>("endCustomerId", NumericArrayParam);
    const [isPilotInspectionParam, setIsPilotInspectionParam] = useQueryParam<boolean | undefined | null>("isPilotInspection", BooleanParam);

    const [trucks, trucksAsync] = useAsyncState<ScheduledTruckDto[]>();
    const [updatingPilotInspection, setUpdatingPilotInspection] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        pageSize: DEFAULT_PAGE_SIZE,
        current: 1,
    });
    const [filter, setFilter] = useState<TruckFilter>( {
    ...DEFAULT_FILTER,
    search: searchFilterParam || undefined, //silly fix for null
    dealerLst: (dealerFilterParam || undefined) as (string[] | undefined), //silly fix for null
    salespersons: [salespersonsParam as (string|null) || []].flat(),
    endCustomerId: endCustomerParam as number[] || undefined,
    isPilotInspection: isPilotInspectionParam || undefined,
    isMasterSchedule: true,
  });
    const [showCalendarView, setShowCalendarView] = useState<number>(0);
    const cancelTokenSourceRef = useRef<CancelTokenSource>();

    useEffect(() => {
      if (!showCalendarView) {
        loadSchedule(trucksAsync, pagination, filter);
      }
    }, [pagination.pageSize, pagination.current, filter]);

    const onFilterChange = (_values: Record<string, any>, filter: TruckFilter) => {
        var filter1: any = { ...filter, search: filter.search == '' ? undefined : filter.search };
        filter1 = filter['dateRange'] ? { ...filter, dateFilterStart: filter['dateRange'][0], dateFilterEnd: filter['dateRange'][1] } : filter1;
        filter1 = {...filter1, isMasterSchedule: true};
        setFilter(filter1);
        setPagination({ ...pagination, current: 1 });

        setDealerFilterParam(filter.dealerLst);
        setSearchFilterParam(filter.search);
        setSalespersonsParam(filter.salespersons);
        setIsPilotInspectionParam(filter.isPilotInspection);
        !!filter.endCustomerId?.length && setEndCustomerParam(filter.endCustomerId);
    };

  const handleToggleCalendarView = () => {

    const nextCalendarView = !showCalendarView;
    const dateFilter = ( nextCalendarView ) 
      ? {
        dateFilterStart: getDateFilterStartEndAround(dayjs(new Date()))[0],
        dateFilterEnd: getDateFilterStartEndAround(dayjs(new Date()))[1],
      }
      : {
        dateFilterStart: DEFAULT_FILTER.dateFilterStart,
        dateFilterEnd: DEFAULT_FILTER.dateFilterEnd
      };

    const pageSize = (nextCalendarView ) ? PAGINATION_MAX_PAGE_SIZE : DEFAULT_PAGE_SIZE;
    setPagination({
      ...pagination,
      pageSize ,
    });

    setFilter({
      ...filter,
      ...dateFilter
    });

    setShowCalendarView(!showCalendarView ? 1 : 0);

    //re-init trucks for cleaner transition
    // trucksAsync.setInit();
  }

    const loadSchedule = useCallback(debounce( async (trucksAsync:AsyncState<ScheduledTruckDto[]> | undefined, pagination: TablePaginationConfig, filter:TruckFilter) : Promise<ScheduledTruckDto[] | undefined> => {

      if ( cancelTokenSourceRef.current ) {
        cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
      }
      const cancelSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = cancelSource;

      trucksAsync?.setLoading();
      try {
        const page = pagination.current || 1;
        const schedule = await configuratorContext.api.listMasterSchedule({ ...filter, page: page - 1, size: pagination.pageSize || DEFAULT_PAGE_SIZE, sort:DEFAULT_PAGE_SORT }, cancelSource.token);
        cancelTokenSourceRef.current = undefined;

        trucksAsync?.setDone(schedule.content);
        setPagination({ ...pagination, total: schedule.totalElements });

        return schedule.content;
      }
      catch (e:any) {
        const id = e.response?.data?.message || e.message ;
        if ( id !== AXIOS_CANCEL_MSG ) {
          const errorMsg = intl.formatMessage({ id });
          notification.error( { message: 'Failed to load master schedule at this time.' + errorMsg });
          trucksAsync?.setFail(e.message);
        }
      }

      return;
    }, 800), []);

    function onPilotInspectionDateUpdated(t: ScheduledTruckDto, date: dayjs.Dayjs|null) {
      updatePilotInspection(t, true, date?.startOf('day')?.toDate());
    }

    async function updatePilotInspection(t: ScheduledTruckDto, val: boolean, date?: Date) {
        try {
            setUpdatingPilotInspection(true);
            await configuratorContext.api.updatePilotInspection(t.truck.id, { pilotInspection: val, pilotInspectionDate: date });
            t.truck.pilotInspection = val;
            t.truck.pilotInspectionDate = date;
        }
        catch (e) {
            notification.error({ message: 'Failed to update pilot inspection status at this time.' });
        }
        finally {
            setUpdatingPilotInspection(false);
        }
    }

    function getPilotInspection(t: ScheduledTruckDto) {
        return (
          configuratorContext.hasPermission(Permission.MASTER_SCHEDULE_PILOT_INSPECTION_WRITE) ? 
          <div style={{display: 'flex', gap: '10px'}}>
            <Checkbox checked={t.truck.pilotInspection} disabled={updatingPilotInspection} 
              onChange={(e: any) => {
                  updatePilotInspection(t, e.target.checked, t.truck.pilotInspectionDate||undefined);}} 
            />
            {t.truck.pilotInspection && 
              <DatePicker disabled={updatingPilotInspection} 
                          defaultValue={t.truck.pilotInspectionDate ? dayjs(t.truck.pilotInspectionDate).tz('utc') : undefined}
                          onChange={(val) => onPilotInspectionDateUpdated(t, val)}
              />
            }
          </div> : 
          <div style={{display: 'flex', gap: '10px'}}>
            <Tooltip title={t.truck.pilotInspectionDate ? dayjs(t.truck.pilotInspectionDate).format('DD/MM/YYYY') : null}>
              <Checkbox checked={t.truck.pilotInspection} disabled={true}/>
            </Tooltip>
          </div>
        );
    }

    const getDateDisplay = (confirmed: Date | null | undefined, estimated: Date | null | undefined) => {
      if (!confirmed && !estimated) return { date: 'Unavailable' };

      const date = confirmed || estimated;
      const color = confirmed ? 'green' : 'orange';
      return {
        color,
        date: getDateOnLocal(date)
      };
    }

    function onChange(pagination: TablePaginationConfig) {
      setPagination(pagination);
    }


    const getCustomerRequestedShippingdate = (t: ScheduledTruckDto) => {
      return !!t.truck.truckCustomerShippingDate ? 
                dayjs(t.truck.truckCustomerShippingDate).format('MM/DD/YYYY') : 
                (!!t.quote.customerShippingDate ? dayjs(t.quote.customerShippingDate).format('MM/DD/YYYY') : 'Unavailable')
    }

    const getDateOnLocal = (date: Date | null | undefined): string => {
      return date ? dayjs.utc(date).tz("America/New_York", true).format('MM/DD/YYYY') : 'Unavailable';
    }

    const ExportButton = () => {
      const [isExporting, setIsExporting] = useState(false);

      const handleExport = async () => {
        try {

          setIsExporting(true);

          const columns = [
            'Line', 'Serial Number', 'VIN', 'Dealer', 'Customer', 'Model', 'Part Number', 'Destination', 'Cab Type', 'Production Date', 'Ready To Ship Date', 'Ship Date', 'Customer Requested Date', 'Fuel Type', 'Engine Sn', 'Transmission Sn', 'Pilot Inspection', 'Production Status'
          ];
          const headers = getCSVRow(columns);

          const data = ( await configuratorContext.api.listMasterSchedule({ ...filter, page: 0, size: PAGINATION_MAX_PAGE_SIZE, sort:DEFAULT_PAGE_SORT }) ).content
            .map((report:ScheduledTruckDto) => 
              getCSVRow([ 
                report.truck.productionLine || '',
                report.truck.truckSerialNumberStr,
                report.truck.vin || '',
                report.quote?.owner.dealerName || '',
                report.quote.endCustomer?.name || '',
                report.quote.model?.name || '',
                report.quote.partNumberString || '',
                report.quote.shippingDestination?.name || '',
                report.cabStyle || '',
                getDateDisplay(report.truck.epicorProductionDate, report.truck.productionDate).date,
                getDateDisplay(report.truck.epicorReadyToShip, report.truck.readyToShip).date,
                getDateDisplay(report.truck.shipDate, report.truck.scheduledShipDate).date,
                getCustomerRequestedShippingdate(report),
                report.fuelType || "",
                report.truck.engineSerialNumber || "",
                report.truck.transmissionSerialNumber || "",
                String( !!report.truck.pilotInspection ),
                report.truck.epicorProductionStatus || ""
              ])).flat();

          const csv = [ headers, ...data];
          var blob = new Blob([csv.join('\n')], { type: 'text/csv;charset=utf-8' });
          var url = URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = 'schedule-export-' + (new Date()) + '.csv';
          document.body.appendChild(a);
          a.click();
        }
        catch (e:any) {
          const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
          notification.error( { message: "Export failed. " + errorMsg });
        }

        setIsExporting(false);

      };

      return <Button type="primary" loading={isExporting} onClick={handleExport}>Export</Button>
    }

    const columns:TableColumnType<ScheduledTruckDto>[] = [
        {
            title: 'Line',
            key: 'productionType',
            render: (t: ScheduledTruckDto) => {
              return t.truck.productionLine?.split('_').pop() || '';
            }
        },
        {
            title: 'Serial Number',
            key: 'truckSerialNumberStr',
            render: (t) => {
                return <Link target="_new" to={'/configurator/' + t.quote.quoteId}>{t.truck.truckSerialNumberStr}</Link>
            },
        },
        {
            title: 'Dealer',
            key: 'dealerName',
            render: (t) => t.quote?.owner.dealerName || '',
        },
        {
            title: 'Customer',
            dataIndex: ['quote', 'endCustomer', 'name'],
            key: 'customerName'
        },
        {
            title: 'Model',
            dataIndex: ['quote', 'model', 'name'],
            key: 'modelName'
        },
        {
            title: 'Part Number',
            dataIndex: ['quote', 'partNumberString'],
            key: 'partNumber'
        },
        {
            title: 'Destination',
            dataIndex: ['quote', 'shippingDestination', 'name'],
            key: 'destination'
        },
        {
            title: 'Cab Type',
            dataIndex: 'cabStyle',
            key: 'cabStyle'
        },
        {
            title: 'Production Date',
            key: 'epicorProductionDate',
            render: (t) => {
              const date = getDateDisplay(t.truck.epicorProductionDate, t.truck.productionDate);
              return <span style={{ color: date.color }}>{date.date}</span>
            },
        },
        {
            title: 'Ready To Ship',
            key: 'epicorReadyToShip',
            render: (t) => {
              const date = getDateDisplay(t.truck.epicorReadyToShip, t.truck.readyToShip);
              return <span style={{ color: date.color }}>{date.date}</span>
            },
        },
        {
            title: 'Ship Date',
            key: 'shipDate',
            render: (t) => {
              const date = getDateDisplay(t.truck.shipDate, t.truck.scheduledShipDate);
              return <span style={{ color: date.color }}>{date.date}</span>
            },
        },
        {
            title: 'Customer Requested Date',
            key: 'customerRequestedShipDate',
            render: (t) => getCustomerRequestedShippingdate(t)
        },
        {
            title: 'Fuel Type',
            key: 'fuelType',
            dataIndex: ['fuelType'],
        },
        {
            title: 'Pilot Inspection',
            key: 'pilotInspection',
            render: getPilotInspection,
        },
        {
            title: 'Production Status',
            key: 'productionStatus',
            dataIndex: ['truck', 'epicorProductionStatus'],
        },
    ];

    return (
        <div className="site-layout-background">
              <Space direction="vertical" style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: "space-between", gap: '5px' }}>
                  <Title level={2}>Master Schedule</Title>
                  <Space>
                    {configuratorContext.hasPermission(Permission.MASTER_SCHEDULE_WRITE) && 
                      <Link to="/master-schedule/upload"><Button type="primary">Upload Schedule</Button></Link>}
                    <ExportButton />
                  </Space>
                </div>
                <Segmented
                  options={[ 
                    { label:"List View", value:0, icon: <UnorderedListOutlined /> }, 
                    { label:"Calendar View", value: 1, icon: <CalendarOutlined />  }
                  ]}
                  onChange={handleToggleCalendarView}
                  value={showCalendarView}
                />

                    {showCalendarView ?
                          <ScheduleCalendarView/>
                        : <Skeleton loading={!trucks} active style={{marginTop: "1rem"}}>
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <TruckFilterControls
                                    loading={!trucks}
                                    filter={filter}
                                    onFilterChange={onFilterChange}
                                    tableName={'master-schedule'}
                                />
                                <Table 
                                  data-testid="masterScheduleListTable"
                                  loading={trucksAsync.isLoading()} 
                                  pagination={pagination} 
                                  rowKey={(t: ScheduledTruckDto) => t.truck.id} 
                                  dataSource={trucks} 
                                  columns={columns} 
                                  onChange={onChange} 
                                />
                            </Space>
                          </Skeleton>}

            </Space>
        </div>
    );
};

export default MasterScheduleView;
