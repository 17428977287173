import { Button, Calendar, Col, Row, Select, Spin, Tooltip } from "antd";
import { MasterScheduleEvent, MONTHS, MotorFamily, ProductionLine, ScheduledTruck, ShortTimeFormat } from "../../api/models";
import dayjs, { Dayjs } from "dayjs";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ALL } from "./master_schedule_slots";

const years = Array.from({ length: 20 }, (_, i) => dayjs().year() - 10 + i);

interface MonthCalendarProps {
  selectedProductionLines: string[];
  getListData: (date: Dayjs, line: string) => MasterScheduleEvent[];
  onSelectLineTitle: (line: string, id: number, current: Dayjs) => void;
  onDayChange: (date: Dayjs) => void;
  getTruckOnDay: (date: Dayjs, line: string) => ScheduledTruck[] | undefined;
  loading: boolean;
  selectedMonth: Dayjs;
}

const MonthCalendar = ({
  selectedProductionLines, 
  getListData, 
  onSelectLineTitle, 
  onDayChange, 
  getTruckOnDay,
  loading,
  selectedMonth,
}: MonthCalendarProps) => {

  const getPreferredEvent = (eventsList: MasterScheduleEvent[]) => {
    if (!eventsList || eventsList.length === 0) {
        return null; // Handle empty or undefined list
    }

    return eventsList.reduce((preferredEvent: MasterScheduleEvent, currentEvent: MasterScheduleEvent) => {
        // Case 1: One is non-default, prioritize non-default
        if (!preferredEvent?.isDefault && currentEvent?.isDefault) {
            return preferredEvent;
        }
        if (preferredEvent?.isDefault && !currentEvent?.isDefault) {
            return currentEvent;
        }

        // Case 2: Both are default or both are non-default
        const preferredCreatedAt = preferredEvent?.createdAt ? new Date(preferredEvent.createdAt) : new Date(0); // It's impossible to have null createdAt
        const currentCreatedAt = currentEvent?.createdAt ? new Date(currentEvent.createdAt) : new Date(0);

        // Choose the one with the latest createdAt
        return currentCreatedAt > preferredCreatedAt ? currentEvent : preferredEvent;
    }, eventsList[0]);
  };


  const getCellData = (current: Dayjs, line: string) => {

    const productionLineEventList = getListData(current, line);
    const lowestTaktEvent = getPreferredEvent(productionLineEventList);

    const trucksOnLine = getTruckOnDay(current, line)

    const bevTrucks = trucksOnLine?.filter(t => t?.motorFamily === String(MotorFamily.BEV)) || [];
    const iceTrucks = trucksOnLine?.filter(t => String(t?.motorFamily) === String(MotorFamily.ICE)) || [];

    const bevSerials = bevTrucks?.map(truck => truck.serialNumber) || [];
    const iceSerials = iceTrucks?.map(truck => truck.serialNumber) || [];

    const iceLineOpen = (lowestTaktEvent?.iceTaktRate || 0) - iceSerials.length;
    const bevLineOpen = (lowestTaktEvent?.bevTaktRate || 0) - bevSerials.length;

    const iceColor = iceLineOpen === 0 ? 'darkgreen' : 'rgba(255, 0, 0, 1)';
    const bevColor = bevLineOpen === 0 ? 'darkgreen' : 'rgba(255, 0, 0, 1)';

    const color = (iceLineOpen === 0 && bevLineOpen === 0) ? 'darkgreen' : 'rgba(255, 0, 0, 1)';

    const background = (iceLineOpen === 0 && bevLineOpen === 0) ? 'rgba(0, 255, 0, 0.25)' : 'rgba(255, 0, 0, 0.25)';
    const border = (iceLineOpen === 0 && bevLineOpen === 0) ? '3px solid rgba(0, 255, 0, 0.7)' : '3px solid rgba(255, 0, 0, 0.7)';

    return {iceColor, iceLineOpen, bevColor, bevLineOpen, color, lowestTaktEvent, background, border, iceSerials, bevSerials};
  }

  const getMonthStartAndEnd = () => {
    const monthStart = selectedMonth.clone().startOf("month");
    const monthEnd = selectedMonth.clone().endOf("month");
    
    let firstWeekStart = monthStart.clone().startOf("week").add(1, "day")
    if (monthStart.day() === 6) {
      firstWeekStart = monthStart.clone().add(2, "days");
    }
    
    let lastWeekEnd = monthEnd.clone().endOf("week").subtract(1, "day"); // Friday
    if (monthEnd.day() === 0) {
      lastWeekEnd = monthEnd.clone().subtract(2, "day");
    }
    return [firstWeekStart, lastWeekEnd];
  }

  const cellRender = (current: Dayjs, _info: any) => {

    const[firstWeekStart, lastWeekEnd] = getMonthStartAndEnd();
    
    if (current.isBefore(firstWeekStart) || current.isAfter(lastWeekEnd)) {
      return <div style={{ display: "none" }} />; // Skip rendering for extra days
    }

    if (selectedProductionLines.includes(ALL)) {
      const allData = Object.keys(ProductionLine).reduce(
        (acc: {
            bevLineOpen: number;
            iceLineOpen: number;
            iceSerials: number[];
            bevSerials: number[];
            lowestTaktEvent: MasterScheduleEvent | null;
            iceTaktRate: number;
            bevTaktRate: number;
        }, line) => {
            const { bevLineOpen, iceLineOpen, lowestTaktEvent, iceSerials, bevSerials } = getCellData(current, line);
            acc.bevLineOpen += bevLineOpen;
            acc.iceLineOpen += iceLineOpen;
            acc.iceSerials.push(...iceSerials);
            acc.bevSerials.push(...bevSerials);
            acc.lowestTaktEvent = acc.lowestTaktEvent || lowestTaktEvent;
            acc.iceTaktRate += lowestTaktEvent?.iceTaktRate || 0;
            acc.bevTaktRate += lowestTaktEvent?.bevTaktRate || 0;
            return acc;
        },
        {
            bevLineOpen: 0,
            iceLineOpen: 0,
            iceSerials: [],
            bevSerials: [],
            lowestTaktEvent: null,
            iceTaktRate: 0,
            bevTaktRate: 0,
        }
      );
    
      const iceFullyAssigned = allData.iceTaktRate === allData.iceSerials.length;
      const bevFullyAssigned = allData.bevTaktRate === allData.bevSerials.length;

      return (
          <div className="date-cell" key={`${current.format(ShortTimeFormat)}-cell`}>
              <div className="date-area">{current.date()}</div>
              <div
                  className="date-cell single-line-cell"
                  style={{
                      background: allData.iceLineOpen === 0 && allData.bevLineOpen === 0 ? 'rgba(0, 255, 0, 0.25)' : 'rgba(255, 0, 0, 0.25)',
                      height: '80px',
                      borderRadius: '8px',
                      border: allData.iceLineOpen === 0 && allData.bevLineOpen === 0
                          ? '3px solid rgba(0, 255, 0, 0.7)'
                          : '3px solid rgba(255, 0, 0, 0.7)',
                      color: allData.iceLineOpen === 0 && allData.bevLineOpen === 0 ? 'darkgreen' : 'rgba(255, 0, 0, 1)',
                      marginBottom: '5px',
                      width: "100%",
                      position: 'relative',
                  }}
              >
                  <span
                      style={{
                          fontSize: '15px',
                          position: 'absolute',
                          top: '3px',
                          left: '5px',
                      }}
                  >
                      All Lines
                  </span>
                  <Row gutter={24}>
                      <Col>
                          <Tooltip
                              title={allData.iceSerials.join(", ")}
                              overlayInnerStyle={{ background: 'rgba(0, 0, 0, 0.8)', color: 'white' }}
                          >
                              <span style={{ color: iceFullyAssigned ? 'darkgreen' : 'red' }}>
                                  ICE: {allData.iceLineOpen === 0 ? (!!allData.iceTaktRate ? 'FULL' : 'N/A') : allData.iceLineOpen}
                              </span>
                          </Tooltip>
                      </Col>
                      <Col>
                          <Tooltip
                              title={allData.bevSerials.join(", ")}
                              overlayInnerStyle={{ background: 'rgba(0, 0, 0, 0.8)', color: 'white' }}
                          >
                              <span style={{ color: bevFullyAssigned ? 'darkgreen' : 'red' }}>
                                  BEV: {allData.bevLineOpen === 0 ? (!!allData.bevTaktRate ? 'FULL' : 'N/A') : allData.bevLineOpen}
                              </span>
                          </Tooltip>
                      </Col>
                  </Row>

                  <Row>
                    <span 
                      style={{
                        fontSize: '13px',
                        position: 'absolute',
                        bottom: '3px',
                        right: '60px',
                      }}
                    >
                      ICE: {allData.iceTaktRate}
                    </span>
                    <span 
                      style={{
                        fontSize: '13px',
                        position: 'absolute',
                        bottom: '3px',
                        right: '5px',
                      }}
                    >
                      BEV: {allData.bevTaktRate}
                    </span>
                  </Row>
              </div>
          </div>
      );
    }

    return (
      <div className="date-cell" key={`${current.format(ShortTimeFormat)}-cell`}>
        <div className="date-area">{current.date()}</div>
        { Object.keys(ProductionLine).filter(line => selectedProductionLines?.includes(line)).map((line) => 
          {
          
          const {background, border, iceColor, bevColor, color, bevLineOpen, iceLineOpen, lowestTaktEvent, iceSerials, bevSerials} = getCellData(current, line);

          if (!lowestTaktEvent) {
            return <div key={`${line}-content-${current.format(ShortTimeFormat)}`}></div>;
          }

          const lineNumber = line.split('_').pop();
          const iceSlot = lowestTaktEvent?.iceTaktRate || 0;
          const bevSlot = lowestTaktEvent?.bevTaktRate || 0;

          return (
            <div 
              className="date-cell single-line-cell" 
              key={`${current.format(ShortTimeFormat)}-${lineNumber}-cell`}
              style={{
                background: background,
                height: '80px',
                borderRadius: '8px',
                border: border,
                color: color,
                marginBottom: '5px',
                width: "100%",
                position: 'relative',
              }}
            >
              <span 
                style={{
                  fontSize: '15px',
                  position: 'absolute',
                  top: '3px',
                  left: '5px',
                }}
              >
                {`Line ${lineNumber}`}
              </span>
              <Row gutter={24}>
                <Col>
                <Tooltip key={`${line}-tooltip-${current.format(ShortTimeFormat)}`}
                        title={iceSerials?.join(", ")}
                        overlayInnerStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white' }}
                >
                  <span style={{color: iceColor, cursor: "pointer"}} onClick={() => onSelectLineTitle(line, lowestTaktEvent.id || 0, current)}>
                    ICE: {!!iceLineOpen ? iceLineOpen : (!!iceSlot ? 'FULL' : 'N/A')}
                  </span>
                </Tooltip>
                </Col>
                <Col>
                <Tooltip key={`${line}-tooltip-${current.format(ShortTimeFormat)}`}
                        title={bevSerials?.join(", ")}
                        overlayInnerStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white' }}
                >
                  <span style={{color: bevColor, cursor: "pointer"}} onClick={() => onSelectLineTitle(line, lowestTaktEvent.id || 0, current)}>
                    BEV: {!!bevLineOpen ? bevLineOpen : (!!bevSlot ? 'FULL' : 'N/A')}
                  </span>
                </Tooltip>
                </Col>
              </Row>
      
              <Row>
                <span 
                  style={{
                    fontSize: '13px',
                    position: 'absolute',
                    bottom: '3px',
                    right: '60px',
                  }}
                >
                  ICE: {iceSlot}
                </span>
                <span 
                  style={{
                    fontSize: '13px',
                    position: 'absolute',
                    bottom: '3px',
                    right: '5px',
                  }}
                >
                  BEV: {bevSlot}
                </span>
              </Row>
            </div>
          );
          }
        )}
      </div>
    );
  };

  const disabledDate = (date: Dayjs) => {
    // Disable dates not in the current month
    return !date.isSame(selectedMonth, "month");
  };

  const headerRender = ({ value, onChange, onTypeChange }) => {
    const current = selectedMonth;

    const prevMonth = () => {
      onChange(current.subtract(1, 'month'));
    };

    const nextMonth = () => {
      onChange(current.add(1, 'month'));
    };

    const onMonthChange = (month) => {
      const newValue = current.month(month);
      onChange(newValue);
    };

    const onYearChange = (year) => {
      const newValue = current.year(year);
      onChange(newValue);
    };

    return (

        <Row justify={"space-between"} style={{padding: "8px"}}>
          <style>
            {`
              .date-switch .ant-select-selector {
                border-radius: 15px !important;
              }
            `}
          </style>
          <Col>
            {loading && <Spin/>}
          </Col>
          <Col className="date-switch">
            <Button type="text" shape="circle" rootClassName="month-switch" onClick={prevMonth} icon={<LeftOutlined />} />

            {/* Month */}
            <Select
              value={current.month()}
              onChange={onMonthChange}
              style={{ width: 120, margin: '0 8px' }}
              options={MONTHS.map((month, index) => {return {value: index, key: index, label: month}})}
            >
            </Select>

            {/* Year */}
            <Select
              value={current.year()}
              onChange={onYearChange}
              style={{ width: 120, marginRight: '8px' }}
              options={years.map((year) => {return {value: year, key: year, label: year}})}
            />

            <Button type="text" shape="circle" rootClassName="month-switch" onClick={nextMonth} icon={<RightOutlined />} />
          </Col>
        </Row>

    );
  };

  return (
    <>
      <style>{`
        .month-calendar tbody tr td:nth-child(1),  /* Sunday */
        .month-calendar tbody tr td:nth-child(7) { /* Saturday */
          display: none;  /* Hide Sundays and Saturdays */
        }

        .month-calendar .ant-picker-content thead th:nth-child(1),  /* Sunday */
        .ant-picker-content thead th:nth-child(7) { /* Saturday */
          display: none;
        }

        .month-calendar .ant-picker-content thead th {
          font-size: 25px;
          padding-bottom: 20px !important;
        }
            
        .month-calendar .ant-picker-cell-inner {
          min-height: 100px !important;
          display: flex;
          justify-content: center;
          align-items: center !important;
        }

        .month-calendar .date-cell {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 5px 5px 0px 5px !important;
          margin: 5px 5px 3px 5px !important;
          height: auto;
          border-top: 2px solid rgba(5, 5, 5, 0.06);
        }

        .date-area {
          line-height: 24px;
          font-family: Arial, sans-serif;
          font-size: 22px;
          text-align: right;
          width: 100%;
        }

        .month-calendar .single-line-cell {
          align-items: center;
          div {
            font-size: 24px;
          }
        }

        .month-calendar .ant-picker-cell {
          cursor: default; 
        }

        .month-calendar .ant-picker-calendar-date {
          padding: 0 !important;
        }

        .month-calendar .ant-picker-calendar-date-content {
          height: auto !important;

          button {
            width: 20px;
            font-size: 11px;
            padding: 0;
            height: 20px !important;
            border-radius: 50%;
          }
        }

        .month-calendar .ant-picker-calendar-date-value {
          font-family: 'Arial';
          font-size: 22px !important;
        }

        /* Banner style for events */
        .month-calendar .event-banner {
          width: 100%;
          padding: 0 6px;
          margin: 2px 0;
          color: white;
          border-radius: 4px;
          text-align: center;
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          margin-bottom: 5px;
          cursor: pointer;
          transition: background 0.5s ease; 
        }

        .month-calendar .event-banner:hover {
          background: #E0E0E0;
        }

        .month-switch:hover {
          background: white !important;
          color: #1677FF !important;
          scale: 1.3 !important;
        }
      `}</style>
      <Calendar
        className="month-calendar"
        fullCellRender={cellRender}
        onChange={onDayChange}
        mode="month"
        value={selectedMonth}
        headerRender={headerRender}
        disabledDate={disabledDate}
      />
    </>
  );
};


export default MonthCalendar;


